import { getText } from '../../../../i18n';

import Store from '../../store';

import { getFullServiceName } from '../../utils/booking';

import ACTION from './action';

import { IReservationStore, IService } from '../../types/booking';

const LABELS = {
  EMPTYCART: getText('booking:emptyCart'),
  NODATA: getText('emptyCart:noData'),
};

const createNewState = (): IReservationStore => ({
  progress: 0,
  bookingId: null,
  services: null,
  errors: [],
  status: 'InProgress',
  statuses: [],
  startDate: '',
  cart: {},
  cartItemByAnalogRooms: {},
  errorMessages: [],
});

const reducer = (
  action: { type: string, payload: any, err?: string },
  state: IReservationStore,
) => {
  let newState = null;

  switch (action.type) {
    case ACTION.UPDATECART: {
      newState = {
        ...state,
        cart: action.payload,
      };

      break;
    }
    case ACTION.LOADCART: {
      if (!action.payload.Items.length) {
        newState = {
          ...state,
          errors: [
            ...state.errors,
            { Message: LABELS.EMPTYCART },
          ],
        };
      } else {
        const services = action.payload.Items.map((item: IService) => ({ value: getFullServiceName(item) }));

        newState = {
          ...state,
          ...createNewState(),
          services,
          errors: [],
          totalService: services.length,
          cartItems: action.payload.Items,
          cart: action.payload,
        };
      }

      break;
    }
    case ACTION.UPDATESTATUSV2: {
      newState = {
        ...state,
        status: action.payload.CartBookingStatus,
        statuses: action.payload.Items,
        trip: action.payload.Trip,
        trips: action.payload.Trips,
        startDate: action.payload.StartDate,
      };

      break;
    }
    case ACTION.STARTBOKING: {
      if (action.payload) {
        newState = {
          ...state,
          bookingId: action.payload.bookId,
          errorMessages: action.payload.errorMessages,
        };
      } else {
        const err = action.err || LABELS.NODATA;
        newState = {
          ...state,
          errors: [
            ...state.errors,
            { Message: err },
          ],
        };
      }

      break;
    }
    case ACTION.CONTINUEBOOKING: {
      newState = {
        ...state,
        errorMessages: action.payload.errorMessages,
      };

      break;
    }
    case ACTION.UPDATEERROR: {
      newState = {
        ...state,
        errors: [
          ...state.errors,
          ...action.payload,
        ],
      };

      break;
    }
    case ACTION.RESET: {
      newState = {
        ...createNewState(),
      };

      break;
    }
    case ACTION.LOADANALOGROOMS: {
      const { cartItemId, analogRooms } = action.payload;

      const preparedAnalogRooms = (list: { Price: number }[]) => list
        .sort(({ Price: first }, { Price: second }) => first - second)
        .map((listItem, index) => ({
          ...listItem,
          checked: index === 0,
        }));

      const cartItemByAnalogRooms = {
        ...state.cartItemByAnalogRooms,
        [cartItemId]: [...preparedAnalogRooms(analogRooms)],
      };

      newState = {
        ...state,
        cartItemByAnalogRooms,
      };

      break;
    }
    case ACTION.UPDATEANALOGROOMS: {
      const { roomId, cartItemId, value } = action.payload;
      const { cartItemByAnalogRooms } = state;

      let mappedAnalogRooms = cartItemByAnalogRooms[cartItemId];

      Object.keys(cartItemByAnalogRooms).forEach((id) => {
        if (Number(id) === cartItemId) {
          mappedAnalogRooms = cartItemByAnalogRooms[id].map((item: { Guid: string }) => ({
            ...item,
            checked: item.Guid === roomId ? value : !value,
          }));
        }
      });

      newState = {
        ...state,
        cartItemByAnalogRooms: {
          ...state.cartItemByAnalogRooms,
          [cartItemId]: [...mappedAnalogRooms],
        },
      };

      break;
    }

    default: {
      newState = state;

      break;
    }
  }

  return newState;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
