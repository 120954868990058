import Store from './store';
import ACTION from './action';
import { BookingStore, BookingStoreType } from './analogStore';

import { SERVICETYPE } from '../../constants/serviceType';

import { IData, ResType, IError, ICart } from '../../types/booking';
import { PaymentMethodTypes } from '../../types/paymentMethod';

const NOACCESSERROR = 'Недостаточно прав для бронирования поездки. Обратитесь к администратору аккаунта вашей компании';

class Booking {
  api: any;
  store: any;
  errorRequest: null;
  analogStore: BookingStoreType;

  constructor(api: any) {
    this.api = api.cart;
    this.store = Store();
    this.analogStore = BookingStore;
    this.errorRequest = null;
  }

  getState = () => this.store.getState();

  getServiceName = (service: string, data: IData) => {
    let serviceName = '';
    switch (service) {
      case SERVICETYPE.AIR: {
        const places = data.Routes
          .map(route => `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`);
        serviceName = `${places.join('; ')}`;
        break;
      }
      case SERVICETYPE.HOTEL: {
        serviceName = `${data.hotel.Name}`;
        break;
      }
      case SERVICETYPE.TRAIN: {
        serviceName = `${data.StationDepart} ${data.StationArrive}`;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        serviceName = `${data.StartPlace.Address} ${data.EndPlace.Address}`;
        break;
      }
    }

    return serviceName;
  };

  startBookingPaymentMethods = (
    { Id }: { Id: string | null },
    paymentMethod: PaymentMethodTypes,
  ): Promise<string | null | { OrderId: number, ErrorMessages: string[] }> =>
    this.api.startBookingPaymentMethods(Id, paymentMethod)
      .then((res: ResType) => {
        let payload;

        if (typeof res === 'string') {
          payload = {
            bookId: res,
            errorMessages: [],
          };
        } else {
          const { BookId, ErrorMessages } = res;

          payload = {
            bookId: BookId,
            errorMessages: ErrorMessages,
          };
        }

        this.analogStore.startBooking(payload);
        this.store.dispatch({
          type: ACTION.STARTBOKING,
          payload,
        });

        return typeof res === 'string' ? null : res;
      })
      .catch((e: IError) => {
        const Message = e.status === 403 ? NOACCESSERROR : e.message;
        const { cart: { Items } } = this.getState();
        const errors = [{
          Message,
          CartItem: Items[0],
        }];

        this.analogStore.updateError(errors);
        this.store.dispatch({
          type: ACTION.UPDATEERROR,
          payload: errors,
        });
      });

  getBookingStatusPaymentMethods = async (id:string | null = null) => {
    let bookingId;

    if (id) {
      bookingId = id;
    } else {
      bookingId = this.getState().bookingId || this.getState().cart.BookId;
    }

    if (!bookingId) {
      return;
    }

    const payload = await this.api.getBookingStatusPaymentMethods(bookingId);

    this.analogStore.updateBookingStatus(payload);
    this.store.dispatch({
      type: ACTION.UPDATESTATUSV2,
      payload,
    });
  };

  updateCart = (payload: ICart) => {
    this.analogStore.updateCart(payload);
    this.store.dispatch({
      type: ACTION.UPDATECART,
      payload,
    });
  };

  applyChanges = async (cartId: string, itemId: string | null, apply: boolean) => {
    await this.api.applyChanges(cartId, itemId, apply);
    this.store.dispatch({ type: ACTION.APPLYCHANGES });
  };

  applyChangesHotel = async (cartItemId: string, rateId: string) => {
    const { bookingId } = this.getState();

    if (!bookingId) {
      return;
    }

    await this.api.applyChangesHotel(cartItemId, rateId, bookingId);
  };

  continueBookingPaymentMethods = async (cartId: string) => {
    const res = await this.api.continueBookingPaymentMethods(cartId);
    const errorMessages = res?.ErrorMessages ?? [];

    this.analogStore.continueBooking({ errorMessages });

    return this.store.dispatch({
      type: ACTION.CONTINUEBOOKING,
      payload: { errorMessages },
    });
  };

  cancelBookingPaymentMethods = (cartId: string) => this.api.cancelBookingPaymentMethods(cartId);

  setCartItem = (item: ICart) => {
    this.store.dispatch({
      type: ACTION.LOADCART,
      payload: item,
    });

    this.analogStore.loadCart(item);

    return item;
  };

  resetStore = () => {
    this.store.dispatch({
      type: ACTION.RESET,
    });
    this.analogStore.reset();
  };

  loadAnalogRooms = (cartItemId: string, rateId: string) => this.api.loadAnalogRooms(rateId).then((res = []) => {
    const analogRooms = {
      cartItemId,
      rateId,
      analogRooms: res || [],
    };

    this.analogStore.loadAnalogRooms(analogRooms);

    this.store.dispatch({
      type: ACTION.LOADANALOGROOMS,
      payload: analogRooms,
    });
  });

  updateAnalogRooms = (roomId: string, cartItemId: string, value: string) => {
    const analogRooms = { roomId, cartItemId, value };

    this.analogStore.updateAnalogRooms(analogRooms);

    this.store.dispatch({
      type: ACTION.UPDATEANALOGROOMS,
      payload: analogRooms,
    });
  };

  subscribe = (cb: any) => this.store.subscribe(cb);
}

export default Booking;
