// @ts-nocheck
import moment from 'moment';
import debounce from '../../api/debounce';

import CARTACTION from './action';
import CartStore from './cartStore';

import { SERVICETYPE } from '../../constants/serviceType';
import { MainAnalytic } from '../../utils/analytics';
import { CART_STATUS } from '../../constants/cart';
import { prepareAddEmployeeCompanyData } from '../../utils/companies';
import { alertDev } from '../../utils/alert';
import { withIM, withInternalMethod } from '../utils/dectrators';
import NetworkStatesStore from '../utils/network/networkStatesStore';

const getMinMaxDate = ({ checkin, checkout, minDate, maxDate }) => {
  let minDateValue = minDate === null ? checkin.clone() : minDate.clone();

  minDateValue = (checkin.isBefore(minDateValue)) ? checkin : minDateValue;
  const maxDateValue = (checkout.isAfter(maxDate)) ? checkout : maxDate;

  return { min: minDateValue, max: maxDateValue };
};

class Cart {
  networkStore = new NetworkStatesStore<'status1C' | 'removeOnlyCartItem'>();

  constructor(api) {
    this.api = api.cart;
    this.apiFeatureFlags = api.featureFlags;
    this.apiAccountSettings = api.accountSettings;
    this.cartStore = CartStore();
    this.mandatoryVerificationCode = false;
  }

  get = () => this.cartStore.getState();

  start = () => this.cartStore.dispatch({
    type: CARTACTION.CARTLOAD,
  });

  updateCartSuccess = (res) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATE,
      payload: res,
    });

    return res;
  };

  updateTripIds = (res) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATE_TRIP_BY_IDS,
      payload: res,
    });
  };

  updateCartError = (status) => {
    if (status === 404) {
      this.cartStore.dispatch({
        type: CARTACTION.UPDATEERROR,
        payload: [],
      });
    }
  };

  loadTripsByTripId = async (cart) => {
    const tripsWithTripIds = cart.flatMap(item => item.Items.filter(trip => trip.TripId));

    if (tripsWithTripIds.length) {
      const tripIds = await this.api.getInfoByTripIds(tripsWithTripIds.map(item => item.TripId));
      this.updateTripIds(tripIds);
    }

    return Promise.resolve();
  };

  load = async () => {
    try {
      const res = await this.api.get();

      await this.loadTripsByTripId(res);
      await this.updateCartSuccess(res);
    } catch (err) {
      this.updateCartError(err.status);
    }
  };

  getNormalCarts = () => {
    const { carts } = this.cartStore.getState();

    return carts.filter(cart => cart.status === CART_STATUS.NORMAL);
  };

  getUniqueEmployeesFromCart = () => {
    const normalCarts = this.getNormalCarts();

    const employeesIds = normalCarts
      .flatMap(({ sources }) => sources
        .flatMap(({ Employees }) => Employees
          .flatMap(({ Employee: { ExternalId } }) => ExternalId)));

    return Array.from(new Set(employeesIds));
  };

  checkUnlockCart = () => {
    const normalCarts = this.getNormalCarts();

    return normalCarts.every(({ unlock }) => unlock);
  };

  checkVerificationCodeForEmployees = () => {
    const uniqueEmployeesIds = this.getUniqueEmployeesFromCart();
    const isCartUnlock = this.checkUnlockCart();

    if (uniqueEmployeesIds.length && isCartUnlock) {
      const data = {
        Employees: uniqueEmployeesIds,
      };

      this.checkVerificationCode(data)
        .then((res) => {
          this.cartStore.dispatch({
            type: CARTACTION.GETVERIFICATIONCODE,
            payload: res,
          });
        });
    }
  };

  setMandatoryVerificationCode = (value) => {
    this.mandatoryVerificationCode = value;

    if (value) {
      this.checkVerificationCodeForEmployees();
    }
  };

  cancellationInfo = async (itemId, cartId) => {
    try {
      const response = await this.api.cancellationInfo(itemId);

      this.cartStore.dispatch({
        type: CARTACTION.UPDATE_RULES,
        payload: {
          itemId,
          cartId,
          rules: response,
        },
      });
    } catch (error) {
      this.cartStore.dispatch({
        type: CARTACTION.UPDATE_RULES,
        payload: {
          itemId,
          cartId,
          rules: '',
        },
      });
    }
  };

  setAdditionalFeeSmartagentCart = (data) => this.api.setAdditionalFeeSmartagentCart(data);

  removeAdditionalFeeSmartagentCart = (cartItemId: number | string) => this.api.deleteAdditionalFeeSmartagentCart(cartItemId);

  checkVerificationCode = data => this.api.checkVerificationCode(data);

  getBookingStatus = id => this.api.getBookingStatus(id);

  getBookingError = id => this.api.getBookingError(id);

  getBookingSuccess = id => this.api.getBookingSuccess(id);

  checkout = data => this.api.checkout(data);

  saveVerificationCode = (id, code) => this.api.saveVerificationCode(id, code);

  sendToApprove = (data, isPreSteps, requestId) => (isPreSteps ? this.api.sendToApproveRequest(data, requestId) : this.api.sendToApprove(data));

  sendToOfflineChat = cartId => this.api.sendToOfflineChat(cartId);

  decideCartApprove = (data, approveId) => this.api.decideApprove(data, approveId);

  getItem = id => this.api.getItem(id).then((res) => this.updateCartItem(res));

  updateCartItem = (cartItem) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATEITEM,
      payload: cartItem,
    });
  };

  updateCartItems = (cartItems) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATE_ITEMS,
      payload: cartItems,
    });
  };

  getCartById = async (id) => {
    this.start();

    const res = await this.api.getCartById(id);

    await this.loadTripsByTripId([res]);

    return res;
  };

  loadWaitingApproveList = () => this.api.waitingApproveList();

  getWaitingApproveList = () => this.loadWaitingApproveList()
    .then(
      list => this.cartStore.dispatch({
        type: CARTACTION.UPDATE,
        payload: list,
      }),
    );

  getDataForCheckout = (cartItems) => {
    let minDate = null;
    let maxDate = moment();

    cartItems.forEach((item) => {
      switch (item.ServiceType) {
        case SERVICETYPE.AIR: {
          const data = JSON.parse(item.Data);

          const firstRoute = data.Routes[0];
          const lastRoute = data.Routes[data.Routes.length - 1];

          const firstSegment = firstRoute.Segments[0];
          const lastSegment = lastRoute.Segments[lastRoute.Segments.length - 1];

          const checkin = moment(firstSegment.DepartureTime_DateTime).utcOffset('+3:00'); // TODO: fucking timzone
          const checkout = moment(lastSegment.ArrivalTime_DateTime).utcOffset('+3:00'); // TODO: fucking timzone

          const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

          minDate = min;
          maxDate = max;

          break;
        }
        case SERVICETYPE.HOTEL: {
          const meta = JSON.parse(item.Data);

          const checkin = moment(meta.checkin);
          const checkout = moment(meta.checkout);

          const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

          minDate = min;
          maxDate = max;

          break;
        }
        case SERVICETYPE.TRANSFER: {
          const meta = JSON.parse(item.Data);

          const checkin = moment(meta.DateArrival);

          const { min, max } = getMinMaxDate({ checkin, checkout: checkin, minDate, maxDate });

          minDate = min;
          maxDate = max;

          break;
        }
        case SERVICETYPE.TRAIN: {
          const data = JSON.parse(item.Data);

          const checkin = moment(data.DateDeparture);
          const checkout = moment(data.DateArrive);

          const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate });

          minDate = min;
          maxDate = max;

          break;
        }
      }
    });

    return { minDate, maxDate };
  };

  moveToNotepad = (item) => {
    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.ADDTONOTE, {
      label: MainAnalytic.LABELS.CART[item.ServiceType],
    });

    return this.api.moveToNotepad(item);
  };

  moveToNotepadMulti = (items) => {
    const searchPromises = items.map(item => this.moveToNotepad(item));

    return Promise.all(searchPromises);
  };

  employeeToCart = (id, employeeId, action) => {
    if (action === 'delete') {
      return this.api.removeEmployeeToCart(id, employeeId);
    }

    return this.api.employeeToCart(id, employeeId);
  };

  changeEmployeePassport = (cartId, employeeId, passportId) => this.api.changePassport(cartId, employeeId, passportId);

  clearCart = (id) => this.api.clear(id);

  removeCart = (id, airToNote) => this.api.remove(id, airToNote).then(() => this.cartStore.dispatch({
    type: CARTACTION.REMOVECART,
    payload: id,
  }));

  removeCartItem = (id, cartId) => this.api.removeCartItem(id).then(() => {
    this.cartStore.dispatch({
      type: CARTACTION.REMOVECARTITEM,
      payload: {
        id,
        cartId,
      },
    });

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }
  });

  @withIM((o) => o.networkStore.withErrorBoundary('removeOnlyCartItem'))
  removeOnlyCartItem(id) {
    return this.api.removeCartItem(id);
  }

  updateCartItemsAfterRemove = (id, cartId) => {
    this.cartStore.dispatch({
      type: CARTACTION.REMOVECARTITEM,
      payload: {
        id,
        cartId,
      },
    });

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }
  };

  removeCartMultiItem = (ids, cartId) => ids.forEach(id => this.removeCartItem(id, cartId));

  rename = ({ name, id }) => this.api.rename({ name, id })
    .then(() => {
      this.cartStore.dispatch({
        type: CARTACTION.UPDATENAME,
        payload: name,
      });
    });

  addEmployee = (id, employeeId) => this.api.employeeToCart(id, employeeId).then(async (res) => {
    await this.getItem(id);

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }

    return res;
  });

  setEmployeeInCartItem = async (
    data: {
      EmployeeId: number,
      CartItemIds: string[],
      TravelApprovalRequestId: string | number | null
    }) => {
    const res = await this.api.setEmployeeInCartItem(data);
    this.updateCartItems(res);

    return res;
  };

  addMultiEmployee = (ids, employeeId) => {
    const searchPromises = ids.map(id => this.addEmployee(id, employeeId));

    return Promise.all(searchPromises);
  };

  removeEmployee = async (cartItemIds, employeeId) => {
    const res = await this.api.removeEmployeeToCart(cartItemIds, employeeId);
    this.updateCartItems(res);

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }

    return res;
  };

  setDepartmentOnlyCompany = (Id, employeeId, companyId) => this.api.getItem(Id)
    .then(res => {
      const departmentArray = res.Employees[0].Employee.Departments;
      const departmentId = departmentArray[0].DepartmentId;
      const isAmountDepartments = departmentArray.length === 1 ? companyId + departmentId : companyId;

      return this.addEmployeeCompany(Id, employeeId, isAmountDepartments);
    });

  resetVerificationCodeError = () => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATEVERIFICATIONCODERROR,
      payload: false,
    });
  };

  checkCode = (code) => {
    const uniqueEmployeesIds = this.getUniqueEmployeesFromCart();

    if (uniqueEmployeesIds.length) {
      const data = {
        Code: code,
        Employees: uniqueEmployeesIds,
      };

      this.checkVerificationCode(data)
        .then((res) => {
          this.cartStore.dispatch({
            type: CARTACTION.CHECKVERIFICATIONCODE,
            payload: res,
          });
        });
    }
  };

  addEmployeeDocument = (id, employeeId, documentId) => this.api.changePassport(id, employeeId, documentId).then(() => this.getItem(id));

  addEmployeeCompany = async (id, employeeId, companyId) => {
    const res = await this.api.employeeCompanyToCart(id, employeeId, prepareAddEmployeeCompanyData(companyId));
    this.updateCartItem(res);

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }

    return res;
  };

  addEmployeeProject = async (id, employeeId, projectId) => {
    const res = await this.api.employeeProjectToCart(id, employeeId, projectId);
    this.updateCartItem(res);

    if (this.mandatoryVerificationCode) {
      this.checkVerificationCodeForEmployees();
    }
  };

  delEmployeeProject = async (id, employeeId) => {
    const res = await this.api.employeeProjectToCart(id, employeeId, null);

    this.updateCartItem(res);
  };

  updateTransfer = (id, data) => this.api.updateTransfer(id, data);

  @withInternalMethod((o) => o.networkStore.withErrorBoundary('status1C'))
  async setRequestForItem(data) {
    const {
      requestId,
      cartItemId,
    } = data;

    if (requestId === 'remove') {
      await this.api.unsetRequestForItem(cartItemId);
    } else {
      await this.api.setRequestForItem(data);
    }

    this.cartStore.dispatch({
      type: CARTACTION.SET_AUTOCOMPLETED_ITEMS,
      payload: { [cartItemId]: requestId },
    });
    this.cartStore.dispatch({
      type: CARTACTION.SET_PRESELECT_REQUEST_1C,
      payload: null,
    });
  }

  @withInternalMethod((o) => o.networkStore.withErrorBoundary('status1C'))
  async loadSuggestedRequests(id, serviceType) {
    const preselect = this.get().preselectedRequest1c;
    const list = await this.api.loadSuggestedRequests(id);

    list.forEach((suggest) => {
      const { RequestId, RequestItemIds } = suggest;

      if (serviceType === preselect?.type
        && preselect?.id === RequestId) {
        this.setRequestForItem({
          cartItemId: id,
          requestId: RequestItemIds[0],
        });
      }
    });

    this.cartStore.dispatch({
      type: CARTACTION.SET_REQUESTS_1C_LIST,
      payload: { id, list },
    });
  }

  suggestedRequests = (itemId, withPrefix) => {
    const list = this.get().request1cList[itemId];

    if (list?.length > 0) {
      return list.map(({ RequestId, RequestItemIds }) => ({
        value: RequestItemIds[0],
        label: `${withPrefix} ${RequestId}`,
      }));
    }

    return [];
  };

  updateTransferAndTp = async (id, data, isNeedUpdateTp = false) => {
    try {
      const res = await this.updateTransfer(id, data);

      if (isNeedUpdateTp) {
        this.cartStore.dispatch({
          type: CARTACTION.UPDATE_ITEM_TP,
          payload: res,
        });
      }
    } catch (e) {
      alertDev(e);
    }
  };

  getRecommendedTime = (params) => this.api.getRecommendedTime(params);

  getCheckTime = (params) => this.api.getCheckTime(params);

  updateTimeIsUp = item => this.cartStore.dispatch({
    type: CARTACTION.UPDATEFOUL,
    payload: item,
  });

  updateItemReason = (itemId, reason) => this.api.updateReason(itemId, reason).then(() => this.getItem(itemId));

  reserveItem = (itemId) => this.api.reserveItem(itemId);

  updateReservationFailedItems = (items = []) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATERESERVATIONFAILEDITEMS,
      payload: items,
    });
  };

  justSendUnderageCart = cartId => this.api.sendUnderageCart(cartId);

  sendUnderageCart = (cartId) => {
    this.cartStore.dispatch({
      type: CARTACTION.UPDATEUNDERAGE,
      payload: {
        loading: true,
      },
    });

    return this.api.sendUnderageCart(cartId)
      .then(() => {
        this.load();
        this.cartStore.dispatch({
          type: CARTACTION.UPDATEUNDERAGE,
          payload: {
            loading: false,
          },
        });

        return true;
      })
      .catch(() => {
        this.cartStore.dispatch({
          type: CARTACTION.UPDATEUNDERAGE,
          payload: {
            loading: false,
          },
        });

        return false;
      });
  };

  bookFlightCertificateFromCart =
  debounce((tripItemId, isBook) => this.api.bookFlightCertificateFromCart(tripItemId, isBook), 300);

  setFirstNonTotallyFilledItem = id => {
    this.cartStore.dispatch({
      type: CARTACTION.SET_FIRST_NON_TOTALLY_FILLED_ITEM,
      payload: id,
    });
  };

  resetFirstNonTotallyFilledItem = () => {
    this.cartStore.dispatch({
      type: CARTACTION.RESET_FIRST_NON_TOTALLY_FILLED_ITEM,
    });
  };

  getTripsToAddOrder = async (Statuses) => {
    try {
      const res = await this.api.getTripsToAddOrder({
        Statuses: [Statuses],
      });

      this.cartStore.dispatch({
        type: CARTACTION.SET_TRIPS_TO_ADD_ORDER,
        payload: res.Trips,
      });
    } catch (err) {
      this.updateCartError(err.status);
    }
  };

  saveAddingTrip = async (data) => {
    try {
      await this.api.addingTripInCart(data);
      await this.load();
    } catch (err) {
      this.updateCartError(err.status);
    }
  };

  deleteAddedTrip = async (cartItemId) => {
    try {
      await this.api.deleteAddedTrip(cartItemId);

      await this.getItem(cartItemId);
    } catch (err) {
      this.updateCartError(err.status);
    }
  };

  setAnalytics = async (cartId, analyticsValueId) => {
    try {
      const res = await this.api.setCustomAnalytics(cartId, analyticsValueId);

      return this.updateCartSuccess([res]);
    } catch (err) {
      return this.updateCartError(err.status);
    }
  };

  setAnalyticsSpecificCart = async (cartId, analyticsValueId) => {
    try {
      return this.api.setCustomAnalytics(cartId, analyticsValueId);
    } catch (err) {
      return this.updateCartError(err.status);
    }
  };

  unsetAnalytics = async (cartId, analyticsValueId) => {
    try {
      const res = await this.api.unsetCustomAnalytics(cartId, analyticsValueId);

      return this.updateCartSuccess([res]);
    } catch (err) {
      return this.updateCartError(err.status);
    }
  };

  unsetAnalyticsSpecificCart = async (cartId, analyticsValueId) => {
    try {
      return this.api.unsetCustomAnalytics(cartId, analyticsValueId);
    } catch (err) {
      return this.updateCartError(err.status);
    }
  };

  setAnalyticsForItem = async (cartItemId, analyticsValueId) => {
    const res = await this.api.setCustomAnalyticsForItem(cartItemId, analyticsValueId);
    this.updateCartItem(res);

    return res;
  };

  unsetAnalyticsForItem = async (cartItemId, analyticsValueId) => {
    const res = await this.api.unsetCustomAnalyticsForItem(cartItemId, analyticsValueId);
    this.updateCartItem(res);

    return res;
  };

  setRequest1c = (request) => {
    this.cartStore.dispatch({
      type: CARTACTION.SET_REQUESTS_1C_LIST,
      payload: request,
    });
  };

  setPreselectRequest1c = (request) => {
    this.cartStore.dispatch({
      type: CARTACTION.SET_PRESELECT_REQUEST_1C,
      payload: request,
    });
  };

  subscribeCart = cb => this.cartStore.subscribe(cb);

  reset = () => this.cartStore.dispatch({
    type: CARTACTION.RESET,
  });

  init = () => {
    this.reset();
    this.start();
  };
}

export default Cart;
