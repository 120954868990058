import { Amplitude } from '../../utils/analytics';
import { prepareAddEmployeeCompanyData } from '../../utils/companies';
import { applyType } from '../../utils/note';

import STATUS from '../../constants/status';

import NOTEACTION from './action';
import NoteStore from './noteStore';
import BookingStore from './bookingStore';
import { IAddEmployeeNote, IInfoByTripIds, INoteItem, INotepadStore, IPrepareNoteItem, IResponseGetNotes } from './types';
import { alertDev } from '../../utils/alert';
import { Tab } from '../../types/shared';

class Notepad {
  api: any;
  apiCart: any;
  noteStore: any;
  bookingStore: any;

  constructor(api: any) {
    this.api = api.notepad;
    this.apiCart = api.cart;
    this.noteStore = NoteStore();
    this.bookingStore = BookingStore();
  }

  getIdSelectedItems() {
    const { items } = this.get();

    const result: number[] = [];
    items.forEach((item) => item.Selected && result.push(item.Id));

    return result;
  }

  get = (): INotepadStore => this.noteStore.getState();

  getBooking = () => this.bookingStore.getState();

  start = () => this.noteStore.dispatch({
    type: NOTEACTION.NOTELOAD,
  });

  updateTripIds = (res: IInfoByTripIds[]) => {
    this.noteStore.dispatch({
      type: NOTEACTION.UPDATE_TRIP_BY_IDS,
      payload: res,
    });

    return res;
  };

  loadTripsByTripId = async (note: IResponseGetNotes) => {
    const tripIds: INoteItem[] = note.Items.filter((trip) => trip.TripId);

    if (tripIds.length) {
      const infoByTripIds: IInfoByTripIds[] = await this.apiCart.getInfoByTripIds(tripIds.map((item) => item.TripId));
      await this.updateTripIds(infoByTripIds);
    }

    return Promise.resolve();
  };

  getNodeList = async (isSmartAgent: boolean): Promise<IResponseGetNotes> => {
    const res: IResponseGetNotes = await this.api.get(!isSmartAgent);

    await this.loadTripsByTripId(res);

    return res;
  };

  getUserIdentity = async (login: { UserName: string, AccountId: string }) => {
    try {
      const res = await this.api.getUserIdentity(login);

      this.noteStore.dispatch({
        type: NOTEACTION.NOTE_USER_ID,
        payload: res.id,
      });

      return res.id;
    } catch (e) {
      this.noteStore.dispatch({
        type: NOTEACTION.NOTE_USER_ID,
        payload: null,
      });
    }

    return null;
  };

  setNodeList = (list: IResponseGetNotes, employeeId: string | null, isSmartAgent: boolean) => {
    this.noteStore.dispatch({
      type: NOTEACTION.NOTEUPDATE,
      payload: list,
      employeeId,
      isSmartAgent,
    });
  };

  load = async (isSmartAgent: boolean, employeeId: string | null) => {
    try {
      const res: IResponseGetNotes = await this.api.get(!isSmartAgent);

      await this.loadTripsByTripId(res);

      await this.noteStore.dispatch({
        type: NOTEACTION.NOTEUPDATE,
        payload: res,
        employeeId,
        isSmartAgent,
      });

      return res;
    } catch (e) {
      return alertDev((e as Error)?.message);
    }
  };

  cancelBooking = (noteId: number) => this.api.cancelBooking(noteId);

  remove = (id: string | null = null) => {
    const ids = id ? [id] : this.getIdSelectedItems();

    return this.api.remove(ids);
  };

  multiRemove = (ids: number[]) => this.api.remove(ids);

  removeAllFouls = () => {
    const { fouls } = this.get();
    const ids = fouls.map((foul) => foul.Id);

    return this.api.remove(ids);
  };

  send = (Email: string, Comment: string) => {
    const ids = this.getIdSelectedItems();

    const data = {
      Id: ids,
      Email,
      Comment,
    };

    return this.api.send(data);
  };

  moveToCart = (id: number | null = null) => {
    const ids = id ? [id] : this.getIdSelectedItems();

    return this.api.moveToCart(ids);
  };

  moveToCartMulti = (ids: number[]) => this.api.moveToCart(ids);

  updateTimeIsUp = (item: IPrepareNoteItem) => this.noteStore.dispatch({
    type: NOTEACTION.UPDATEFOUL,
    payload: item,
  });

  changeNoteTypeFilter = (
    noteType: string,
    tripType: string | Tab,
    items: INoteItem[],
    sourcesFouls: INoteItem[],
    userId: string,
  ) => {
    const serviceFilter = typeof tripType === 'object'
      ? tripType.value
      : tripType;
    const list = applyType({ list: items, serviceFilter, noteFilter: noteType, userId });
    const foulsList = applyType({ list: sourcesFouls, serviceFilter, noteFilter: noteType, userId, isFoul: true });

    return this.noteStore.dispatch({
      type: NOTEACTION.NOTETYPEFILTERUPDATE,
      noteType,
      tripType,
      list,
      foulsList,
    });
  };

  changeInputSearch = (searchValue: string, serviceType: string) =>
    this.noteStore.dispatch({
      type: NOTEACTION.SEARCHUPDATE,
      payload: {
        searchValue,
        serviceType,
      },
    });

  changeSelectedAll = (selectedAll: boolean) => this.noteStore.dispatch({
    type: NOTEACTION.SELECTALLUPDATE,
    payload: selectedAll,
  });

  changeSelected = (selected: boolean, item: IPrepareNoteItem) => this.noteStore.dispatch({
    type: NOTEACTION.SELECTUPDATE,
    payload: {
      selected,
      item,
    },
  });

  changeMultiSelected = (selected: boolean, itemIds: any) => this.noteStore.dispatch({
    type: NOTEACTION.SELECT_MULTI_UPDATE,
    payload: {
      selected,
      itemIds,
    },
  });

  clearFilters = (isSmartAgent?: boolean, employeeId?: string) => this.noteStore.dispatch({
    type: NOTEACTION.FILTERCLEAR,
    isSmartAgent,
    employeeId,
  });

  closeDialog = () => this.noteStore.dispatch({
    type: NOTEACTION.CLOSEDIALOG,
    payload: null,
  });

  loadBookingItem = (id: number) => this.api.loadBooking(id).then((res: INoteItem) => {
    this.bookingStore.dispatch({
      type: NOTEACTION.UPDATE,
      payload: res,
    });

    return res;
  });

  updateTags = (Id: string, Tags: number[]) => this.noteStore.dispatch({
    type: NOTEACTION.UPDATE_TAGS,
    payload: {
      Id,
      Tags,
    },
  });

  cancellationInfo = async (itemId: number) => {
    try {
      const response = await this.api.cancellationInfo(itemId);

      this.noteStore.dispatch({
        type: NOTEACTION.UPDATE_RULES,
        payload: {
          itemId,
          rules: response,
        },
      });
    } catch (error) {
      this.noteStore.dispatch({
        type: NOTEACTION.UPDATE_RULES,
        payload: {
          itemId,
          rules: '',
        },
      });
    }
  };

  addEmployee = (id: number, employeeId: number): Promise<IAddEmployeeNote> =>
    this.api.addEmployee(id, employeeId).then((res: IAddEmployeeNote) => {
      this.loadBookingItem(id);

      return res;
    });

  removeEmployee = (id: number, employeeId: number): Promise<null> =>
    this.api.removeEmployee(id, employeeId).then((res: null) => {
      this.loadBookingItem(id);

      return res;
    }).catch((err: any) => {
      if (err.response && err.response.status === STATUS.BAD_REQUEST) {
        this.bookingStore.dispatch({
          type: NOTEACTION.UPDATE_ERROR,
        });
      }
    });

  addEmployeeDocument = (id: number, employeeId: number, documentId: number): Promise<null> =>
    this.api.addEmployeeDocument(id, employeeId, documentId)
      .then((res: null) => {
        this.loadBookingItem(id);

        return res;
      }).catch((err: any) => {
        if (err.response && err.response.status === STATUS.BAD_REQUEST) {
          this.bookingStore.dispatch({
            type: NOTEACTION.UPDATE_ERROR,
          });
        }
      });

  resetError = () => this.bookingStore.dispatch({
    type: NOTEACTION.RESET_ERROR,
  });

  downloadBookingVoucher = (id: number, note: string) => this.api.downloadBookingVoucher(id, note);

  downloadDefaultBookingVoucher = (id: number) => this.api.downloadDefaultBookingVoucher(id);

  addEmployeeCompany = (id: number, employeeId: number, companyId: string) =>
    this.api.addEmployeeCompany(id, employeeId, prepareAddEmployeeCompanyData(companyId))
      .then((res: any) => {
        this.loadBookingItem(id);

        return res;
      });

  updateNoteCompany = (id: number, employeeId: string, companyId: string, isSmartAgent:boolean = false) =>
    this.api.addEmployeeCompany(id, employeeId, prepareAddEmployeeCompanyData(companyId))
      .then((res: any) => {
        this.load(isSmartAgent, employeeId);

        return res;
      });

  booking = (id: number) => this.api.booking(id)
    .then((res: any) => {
      Amplitude.pushEvent(Amplitude.TYPE.AIRLINE.SEARCH, res);

      return res;
    });

  getAirTicketName = (item: INoteItem) => {
    const { Data } = item;
    const ticket: any = JSON.parse(Data);

    const result: string[] = [];
    // @ts-ignore
    ticket.Routes.forEach((route) => {
      // @ts-ignore
      route.Segments.forEach((segment) => {
        if (!result.includes(segment.DepartureCity)) {
          result.push(segment.DepartureCity);
        }

        result.push(segment.ArrivalCity);
      });
    });

    return result.join(' – ');
  };

  addEmployeeProject = (id: number, employeeId: number, projectId: number) =>
    this.api.employeeProjectToNote(id, employeeId, projectId).then(() => this.loadBookingItem(id));

  delEmployeeProject = (id: number, employeeId: number) =>
    this.api.delEmployeeProjectFromNote(id, employeeId).then(() => this.loadBookingItem(id));

  setAdditionalFeeSmartagentNotepad = (data: { TripItemId: number, AgentFee: number }) =>
    this.api.setAdditionalFeeSmartagentNotepad(data);

  removeAdditionalFeeSmartagentNote = (tripItemId: number) =>
    this.api.removeAdditionalFeeSmartagentNote(tripItemId);

  subscribeNote = (cb: any) => this.noteStore.subscribe(cb);

  subscribeBooking = (cb: any) => this.bookingStore.subscribe(cb);
}

export default Notepad;
