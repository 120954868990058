const ROUTES = {
  FILES: {
    DOWNLOAD: '/files/file',
    DOWNLOAD_BY_GUID: (guid: string) => `/files/file/reviseact/${guid}`,
  },
  TRAINS: {
    SEARCH: '/train-service/search',
    SEARCH_TRANSFERS: '/train-service/search/transfers',
    AUTOCOMPLETE: '/autocomplete-trains',
    CARDESCRIPTION: '/train-service/carriage',
    GET_CAR_SCHEMA: (trainNumber: string, currentLng: string) => `/train-scheme/train/${trainNumber}/carScheme/?lang=${currentLng}`,
    STATS: {
      ANALYTICS: {
        CARWITHOUTSCHEMA: '/train-analytics/cars/without_schema',
      },
    },
    WARNING_TEMPLATE: '/informant/active/railway',
  },
  TRANSFERS: {
    SEARCH: '/transfer-service/search',
    SEARCH_BY_GUID: (id: string) => `/transfer-service/search/${id}`,
    AUTOCOMPLETE: (query: string) => `/autocomplete-transfers/${query}`,
    TO_CART: (searchId: string, rateId: string) => `/transfers/${searchId}/${rateId}/tocart`,
    RECOMMENDED_TIME: '/transfer-service/recommended-time',
    CHECK_TIME: '/transfer-service/check-time',
    HISTORY: '/history/transfers',
    GET_TEXT_WARNING: '/informant/active/transfer',
    TO_NOTE: (searchId: string, transferId: string) => `/transfers/${searchId}/${transferId}/tonote`,
    GET_REFUND: (tripItemId: string) => `/transfer/${tripItemId}/refund`,
    CANCEL: (tripItemId: string) => `/transfer/${tripItemId}/cancel`,
  },
  AIRLINES: {
    AUTOCOMPLETE: '/autocomplete-airline/internal',
    MULTISEARCH: '/airline/search/multi',
    MULTISEARCH_BY_ID: (id: string) => `/airline/search/multi/${id}`,
    HISTORY: '/airline/search/history',
    REGION_BY_AIRPORT_ID: (airportId: string) => `/airline/airports/${airportId}/region`,
    TO_CART: '/airline/addtocart',
    TO_NOTE: '/airline/addtonote',
    TO_FAVORITE: '/airline/favorite',
    WARNING_TEMPLATE: '/informant/active/avia',
  },
  HOTELS: {
    STATIC: '/hotel-static',
    STATIC_FORCE: (id: string) => `/hotel-static/${id}/force`,
    RATES: '/hotel-service/hotels/',
    HISTORY: '/history/hotel',
    TEMPLATE_WARNING: '/informant/active/hotel',
    STATS: {
      ANALYTICS: '/hotel-analytics/',
      AUTOCOMPLETE: '/autocomplete-hotels/metrics/',
    },
    OFFLINE: '/hotel-service/request-offline',
    START_REGION_SEARCH: '/hotel-service/start-region-search',
    LOAD_BY_GUID: (guid: string) => `/hotel-service/result/${guid}`,
    RADIUS_SEARCH: '/hotel-service/start-radius-search',
    REQUEST_CONTRACT: (action: string, guid: string) => `/external-links/contract/${action}/${guid}`,
    COUNT_RESERVED: (accountId: number) => `/hotel-service/prebook/count/${accountId}`,
    DOWNLOAD_VOUCHER: (noteId: number, locale: string) => `/note/${noteId}/voucher?locale=${locale}`,
  },
  VIP_HALLS: {
    SEARCH: '/viphall-service/services/iata',
    INFO: ({ id, date }: { id: string, date: string }) => `/viphall-service/services/detailed?hallId=${id}&date=${date}`,
    GET_TEXT_WARNING: '/informant/active/viphall',
    CALCULATE_PRICE: '/viphall-service/calculate',
    RECALCULATE_PRICE: '/viphall-service/recalculate',
    PAYMENT_PROJECT_MICROSERVICE: (tripItemId: string, projectId: string) => `/booking-service/viphall/${tripItemId}/${projectId}`,
    PAYMENT: (tripItemId: string) => `/viphall/${tripItemId}`,
    PAYMENT_MICROSERVICE: (tripItemId: string) => `/booking-service/viphall/${tripItemId}`,
    CANCEL: (tripItemId: string) => `/viphall/${tripItemId}/cancel`,
    GET_REFUND: (tripItemId: string) => `/viphall/${tripItemId}/refund`,
    AVAILABLE_AIRPORTS: '/viphall-service/airports/available',
  },
  FAVORITES: {
    GETHOTELS: '/favorites/Hotel/',
  },
  TRAVEL_POLICIES: {
    LOAD: '/travel-policies-service/policies',
    SAVE: '/travel-policies-service/policy-employees',
    GET_BUNDLE: (id: string) => `/travel-policies-service/policy/${id}`,
    SAVE_BUNDLE: '/travel-policies-service/policy/link',
    REMOVE: (id: string) => `/travel-policies-service/policy/${id}`,
    UPDATE_INTERCOM_COMMENT_FOR_USERS: '/users/update-intercom-comment',
    GET_TRAVEL_POLICIES_BY_ID: (id: string) => `/travel-policies-service/policy/${id}`,
    GET_UNIQUE_TRAVEL_POLICY_AFTER_QUESTIONS: '/travel-policies-service/questionnaire/policy/generate',
    GET_DONE_UNIQUE_TRAVEL_POLICY: '/travel-policies-service/policy/ready-made',
    SAVE_UNIQUE_TRAVEL_POLICY: '/travel-policies-service/policy-employees',
    DELETE_QUESTIONS: '/travel-policies-service/questionnaire/questions',
  },
  PAYMENTS: {
    CARD: '/operations/card',
  },
  INSURANCES: {
    RATES: (cartId: string) => `/insurance-service/cart/${cartId}/calculate`,
    RATES_IN_TRIPS: (tripId: string) => `/insurance-service/trip/${tripId}/calculate`,
    CARTITEMS: '/cart-service/cart-wp/item/insurance',
    RECALCULATE: '/insurance-service/trip/recalculate',
  },
  TRIPS: {
    DOWNLOADREPORT: '/analytics/reports/basic-trip/',
    SET_TRIPS_REQUEST: '/request-service/requests/items',
    DOWNLOAD_CALENDAR: (tripId: string) => `/trips/${tripId}/calendar`,
    GET_TRIPS_SMARTAGENT: '/trip-service/wp/trips/upcoming',
  },
  REQUESTS: {
    GET_REQUESTS: '/request-service/requests',
    GET_REQUEST: (id: string) => `/request-service/requests/${id}`,
    GET_TRIP_REQUEST: (filter: string) => `/request-service/requests/ids${filter}`,
  },
  TRIP: {
    SET_ANALYTICS: (tripId: string, analyticsValueId: string) => `/trip/${tripId}/analytics/${analyticsValueId}`,
    SET_ANALYTICS_FOR_ITEM: (tripId: string, tripItemId: string, analyticsValueId: string) =>
      `/trip/${tripId}/items/${tripItemId}/analytics/${analyticsValueId}`,
    UNSET_ANALYTICS: (tripId: string, analyticsId: string) => `/trip/${tripId}/analytics/${analyticsId}`,
    UNSET_ANALYTICS_FOR_ITEM: (tripId: string, tripItemId: string, analyticsId: string) =>
      `/trip/${tripId}/items/${tripItemId}/analytics/${analyticsId}`,
    GET_COMMENTS: (tripId: string) => `/trip-service/trip/${tripId}/comment`,
    ADD_COMMENTS: '/trip-service/trip/comment',
    INFO_TO_ADD_ORDER: '/trip-service/wp/trips/info',
    INFO_BY_TRIPS_IDS: '/trip-service/cache/by_ids',
    GET_STATUS_FLIGHT_CERTIFICATES: (tripItemId: string) => `/booking-service-documents/${tripItemId}/status`,
    GET_STATUS_FLIGHT_CERTIFICATES_FREE: (tripItemId: string) => `/booking-service-documents/${tripItemId}/freecertificates/status`,
    DOWNLOAD_FLIGHT_CERTIFICATE: (tripItemId: string) => `/booking-service-documents/certificates/download/${tripItemId}`,
    BOOK_FLIGHT_CERTIFICATE: (tripItemId: string, projectId: string) => `/flight-certificate/${tripItemId}/${projectId}`,
    SET_FEE_SMARTAGENT: '/smartagent-fee-service/trip/agent-fee',
    DELETE_FEE_SMARTAGENT: (tripItemId: number) => `/smartagent-fee-service/trip/${tripItemId}/agent-fee`,
    DOWNLOAD_LOCALE_VOUCHER: (orderId: string, locale: string) => `/trips/items/${orderId}/voucher/?locale=${locale}`,
  },
  TRIP_PLAN: {
    GET_PLAN: (tripId: number) => `/trip-service/trip/plan/${tripId}`,
    ADD_EVENT: '/trip-service/trip/plan/add-event',
    UPDATE_EVENT: '/trip-service/trip/plan/update-event',
    DELETE_EVENT: (tripEventId: number) => `/trip-service/trip/plan/delete-event/${tripEventId}`,
    DOWNLOAD_EVENT: (tripEventId: number, ownerId: number, needAddVouchers: boolean) =>
      `/booking-service-documents/trip/plan/${tripEventId}/${ownerId}?addvouchers=${needAddVouchers}`,
    SEND_TO_EMAIL: (tripId: number, ownerId: number, email: string, locale: string, addvouchers: any) =>
      `/trip/plan/${tripId}/${ownerId}/send?email=${email}&locale=${locale}&addvouchers=${addvouchers}`,
  },
  APPROVE: {
    DOWNLOADREPORT: '/approve-service-report/download/',
  },
  TAGS: {
    LOAD: '/tags/account',
    ADDTOACCOUNT: '/tags/add',
    GETCARTSAVEROUTE: (cartItemId: string) => `/tags/cart-item/${cartItemId}/save`,
    GETEMPLOYEESAVEROUTE: (employeeId: string) => `/tags/employee/${employeeId}/save`,
    GETNOTESAVEROUTE: (noteItemId: string) => `/tags/note-item/${noteItemId}/save`,
    GETTRIPSAVEROUTE: (tripItemId: string) => `/tags/trip-item/${tripItemId}/save`,
  },
  POPUPS: {
    GET_STATE_BANNERS: '/user-popup/banners',
    SET_STATE_BANNER: '/user-popup/banner',
    MOBILE_APP_SEND_MESSAGE: '/user-popup/mobileAppBanner/send',
    GET_STATE_POPUPS: '/user-popup/popups',
    SET_STATE_POPUP: '/user-popup/popup',
    USERS: {
      EMAILS_BY_IDS: '/user-popup/users/emails/',
    },
    OFFER: '/user-popup/viphall/offer/',
    OFFER_STATE: (tripItemId: string) => `/user-popup/viphall/offer?trip_item_id=${tripItemId}`,
  },
  AEROEXPRESS: {
    GET_ROUTES: '/aeroexpress/routes',
    SEARCH: '/aeroexpress/pricing',
    ADD_TO_CART: '/aeroexpress/to-cart',
    GET_REFUND: '/aeroexpress/refund',
    CANCEL: '/aeroexpress/cancel',
  },
  PROJECTS: {
    GET: '/projects',
    SET: '/projects',
  },
  GET_CITIZENSHIP: '/account-service/account/codes',
  ROLES: {
    GET: '/account/employees/roles',
  },
  EMPLOYEES: {
    GET: '/account/employees',
    GET_OPTIMIZED: '/employees',
    AUTOCOMPLETE_DEPARTMENT: (searchString: string, query: string) =>
      `/employee-service/autocomplete/${searchString}?${query}`,
    GET_EMPLOYEE: (id: string) => `/employees/${id}`,
    WITH_ROLES: '/account/employees-with-rights',
    AUTOCOMPLETE: '/account/employees/autocomplete',
    AUTOCOMPLETE_EMPLOYEE_ONLY: '/employees/autocomplete',
    AUTOCOMPLETE_ONLY: '/employees/autocomplete/travel-policy',
    DOWNLOAD_REGISTRY: '/employee-service/employees-registry/download',
    BY_COMPANY: (companyId: number, adultsOnly: boolean) =>
      `/employee-service/employees/by-company/${companyId}?adultsOnly=${adultsOnly.toString()}`,
  },
  ACCOUNT_ADMINS: {
    GET: '/account/admins',
  },
  FEATURE_FLAGS: {
    GET: '/feature-flags',
    SET_MANDATORY_PROJECTS: '/feature-flags/flags/mandatory-project',
    GET_SETTINGS_FLAGS: (accountId: string) => `/feature-flags/account-settings-get/${accountId}`,
  },
  DEPARTMENT: {
    GET: (id: string) => `/account-service/departments/${id}`,
    DEPARTMENTS_COUNT: '/account-service/departments/count',
    REMOVE: {
      HEAD: (departmentId: number) => `/account-service/departments/${departmentId}/head`,
      EMPLOYEES: (departmentId: number) => `/account-service/departments/${departmentId}/employees`,
    },
    SET: {
      EMPLOYEES: (departmentId: number) => `/account-service/departments/${departmentId}/employees`,
      NAME: (departmentId: number) => `/account-service/departments/${departmentId}/name`,
      HEAD: (departmentId: number, employeeId: number) => `/account-service/departments/${departmentId}/head?employeeId=${employeeId}`,
    },
  },
  DEPARTMENTS: {
    GET: '/departments',
    SET: '/account-service/department',
    GET_BY_FILTERS: '/account-service/departments/view',
    GET_DEPARTMENTS_COMPANIES: '/account-service/companies/departments',
    GET_GROUPED_DEPARTMENTS: (query: object) => `/account-service/departments/group-by-companies?${query}`,
  },
  APPROVAL_SCHEMES: {
    GET: '/approval-schemes',
    APPROVERS: '/approval-schemes/approvers',
    GET_SHORT_INFO: '/travel-approval/schemes/short-info',
    UPDATE: '/approval-schemes',
    REMOVE: '/approval-schemes',
    LINK_TO_EMPLOYEE: '/approval-schemes/link',
    NEW_APPROVERS: '/travel-approval/schemes/approvers',
    GET_USERS_INFO: '/user-service/users/info',
    GET_SCHEME: (id: string) => `/travel-approval/schemes/${id}`,
  },
  APPROVAL_ER_SCHEMES: {
    GET: '/expense-report-approval/approval-schemes',
    SAVE: '/expense-report-approval/approval-schemes',
    REMOVE: '/expense-report-approval/approval-schemes/',
    LINK_TO_EMPLOYEE: '/expense-report-approval/approval-schemes/link',
  },
  S7: {
    GET: '/s7/balance',
    SETCODE: '/s7/code',
    EMAIL: '/s7-profi/email',
    REGISTRATION: '/s7/registration',
  },
  AIRLINE_SEATS: {
    GET_SEAT_MAPS: '/airline/services/seats',
    DELETE_PLACES: (cartItemId: string) => `/cart-service/cart-wp/item/${cartItemId}/airline/seats`,
    AIRLINE_ADDITIONAL_SERVICE: '/airline-service/additional-services/availability',
    AIRLINE_SEATS_BONUS_WARNING_S7: (query: string) => `/airline-service/additional-services/bonus-warning${query}`,
  },
  AIRLINE_BAGGAGE: {
    GET_BAGGAGE_OFFER: (providerName: string, offerId: string) => `/airline/services/baggage/${providerName}/${offerId}`,
    ADD_BAGGAGE_TO_CART: (cartItemId: number) => `/cart-service/cart-wp/item/${cartItemId}/airline/baggages`,
  },
  REPORT: {
    DOWNLOAD_SPECIFIED_REPORT_GSPROM: '/report/gsprom/',
    DOWNLOAD_SPECIFIED_REPORT_SEVMASH: '/analytics-service/wp/trips/sevmash',
    DOWNLOAD_ORIGINAL_TRIP: (id: string) => `/trips/versions/${id}/voucher`,
    REQUEST_ACT: (companyId: string, format: string) => `/report/${companyId}/reviseAct/${format}`,
    DOWNLOAD_PAYMENT_DETAILS: (companyId: string, startDate: string, endDate: string, periodType: string) =>
      `/account-service-billing/payment-visualization/companies/${companyId}/${periodType}/download?startDate=${startDate}&endDate=${endDate}`,
  },
  NOTEPAD: {
    GET: (isSmartDesk: boolean) => `/note?isSmartDesk=${isSmartDesk}`,
    GET_LOGIN: '/user-service/user/user-id',
    GET_CANCELLATION_INFO: (id: string) => `/note/${id}/CancellationInfo`,
    SET_FEE_SMARTAGENT: '/smartagent-fee-service/note/agent-fee',
    DELETE_FEE_SMARTAGENT: (noteItemId: number) => `/smartagent-fee-service/note/${noteItemId}/agent-fee`,
    GET_RESERVATION_NODE: '/cart-service/cart-wp/reserved/count',
    DOWNLOAD_BOOKING_VOUCHER: (noteId: number, locale: string) => `/note/${noteId}/voucher?locale=${locale}`,
    DOWNLOAD_BOOKING_VOUCHER_DEFAULT: (noteId: number) => `/note/${noteId}/voucher`,
  },
  CART: {
    GET_CART_BY_ID: (cartId: string) => `/cart-service/cart-wp/${cartId}`,
    GET_CART_ITEM: (cartId: string) => `/cart-service/cart-wp/item/${cartId}`,
    SET_CART_ITEM_SEGMENT_SEATS: (cartItemId: string, segmentId: string, cartId: string) =>
      `/cart-service/cart-wp/${cartId}/item/${cartItemId}/airline/segment/${segmentId}/seats`,
    SEND_UNDERAGE: (cartId: string) => `/cart/${cartId}/underage`,
    GET_DISCOUNT: (cartId: string) => `/cart/${cartId}/train/discount`,
    APPLY_DISCOUNT: (cartId: string, apply: string) => `/cart/${cartId}/train/discount/${apply}`,
    SET_ANALYTICS_FOR_ITEM: (cartItemId: string) => `/cart-service/cart-wp/item/${cartItemId}/analytics`,
    UNSET_ANALYTICS_FOR_ITEM: (cartItemId: string, analyticsId: string) =>
      `/cart-service/cart-wp/item/${cartItemId}/analytics/${analyticsId}`,
    SET_ANALYTICS: (cartId: string) => `/cart-service/cart-wp/${cartId}/analytics`,
    UNSET_ANALYTICS: (cartId: string, analyticsId: string) => `/cart-service/cart-wp/${cartId}/analytics/${analyticsId}`,
    EMPLOYEE_COMPANY_TO_CART: (id: string, employeeId: string) => `/cart-service/cart-wp/item/${id}/employee/${employeeId}/company`,
    UPDATE_CART_ITEM: (id: string) => `/cart-service/cart-wp/item/${id}/transfer/update`,
    SET_REQUEST_FOR_ITEM: ({ cartItemId, requestId }: { cartItemId: string, requestId: string }) => `/cart-service/cart-wp/item/${cartItemId}/request/${requestId}`,
    UNSET_REQUEST_FOR_ITEM: (cartItemId: string) => `/cart-service/cart-wp/item/${cartItemId}/request`,
    CLEAR_ADDING_TRIP: (id: string) => `/cart-service/cart-wp/items/${id}/clear-trip`,
    SET_EMPLOYEE: '/cart-service/cart-wp/items/employee/',
    ADDING_TRIP: '/cart-service/cart-wp/items/add-trip',
    SET_FEE_SMARTAGENT_CART: '/smartagent-fee-service/cart/agent-fee',
    DELETE_FEE_SMARTAGENT_CART: (cartItemId: string) => `/smartagent-fee-service/cart/${cartItemId}/agent-fee`,
    GET_SUGGESTED_REQUESTS: (cartItemId: string) => `/cart-service/cart-wp/item/${cartItemId}/request/appropriate`,
    SEND_TO_OFFLINE_CHAT: (cartId: string) => `/cart-service/cart-wp/offline/${cartId}/chat`,
  },
  EXPENSE_REPORTS: {
    GET: '/expense-report/reports',
    GET_APPROVERS_SCHEME: (employeeId: string) => `/expense-report-approval/approvers/steps/${employeeId}`,
    GET_APPROVERS: (employeeId: string) => `/expense-report-approval/approvers/${employeeId}`,
    SINGLE: (id: string) => `/expense-report/report/${id}`,
    SINGLE_APPROVE: (id: string) => `expense-report-approval/expense-report/${id}`,
    GET_TRIPS: (employeeId: string, companyId: string) => `/expense-report/trips/${employeeId}?company_id=${companyId}`,
    SETTINGS: '/expense-report/settings',
    SAVE_NEW_REPORT: (employeeId: string) => `/expense-report/report/${employeeId}`,
    SEND_REPORT_FOR_APPROVE: '/expense-report-approval/approve',
    SAVE_REPORT: (reportId: string) => `/expense-report/report/${reportId}`,
    DOWNLOAD_REPORT: (reportId: string, file: string) => `/expense-report/report/${reportId}/file/${file}`,
    SEND_EMAIL: (reportId: string, email: string, file: string) => `/expense-report/report/${reportId}/email/${email}/file/${file}`,
    APPROVES: '/expense-report-approval/approve',
    APPROVE: (id: string) => `/expense-report-approval/approve/${id}`,
    SEND_FILE: '/expense-report/images/new',
    DOWNLOAD_DOCUMENTS_ITEM: (reportId: string, guid: string) => `/expense-report/images?expenseReportId=${reportId}&fileGuid=${guid}`,
    DOWNLOAD_ARCHIVE_DOCS: (reportId: string) => `/expense-report/images/archive?expenseReportId=${reportId}`,
    GET_PREFILED_VALUE: '/expense-report/prefilled',
  },
  CUSTOM_ANALYTICS: {
    GET: '/analytics',
    CREATE: '/analytics',
    GET_BY_ID: (id: string) => `/analytics/${id}`,
    UPDATE: (id: string) => `/analytics/${id}`,
    DELETE: (id:string) => `/analytics/${id}`,
    ADD_VALUE: (id: string) => `/analytics/${id}/values`,
    DELETE_VALUE: (id: string, valueId: string) => `/analytics/${id}/values/${valueId}`,
  },
  UI_SETTINGS: {
    GET_UI: (aId:string) => `/account-service/account/${aId}/aggregation`,
    GET_LNG: '/language-service-settings',
    UPDATE: '/language-service-settings/update',
  },
  SETTINGS_PAGE: {
    EMPLOYEES_VIEW: '/employees/view',
    EMPLOYEES_COUNT: '/employees/view/count',
    EDIT_COMPANY_ORDER: '/account-service/account/order/add',
    AUTOCOMPLETE_EMPLOYEE: '/autocomplete-employee/employees',
    GET_FEE_CONSTRUCTIONS: '/smartagent-fee-service/settings/agent-fee',
    REMOVE_FEE_CONSTRUCTIONS: (serviceType: string) => `/smartagent-fee-service/settings/agent-fee/${serviceType}`,
    GET_BRAND_NAME: (accountId: string) => `/account-service/brand/${accountId}`,
  },
  TRAVEL_APPROVAL: {
    TRAVEL_APPROVAL: '/travel-approval',
    REQUESTS: '/travel-approval/requests',
    USER_REQUESTS: (userId: string) => `/travel-approval/requests/${userId}`,
    APPROVE: '/travel-approval/approve',
    APPROVES: '/travel-approval/approves',
    REQUEST: '/travel-approval/request',
    UNFILTERED: (id: string) => `/travel-approval/request/${id}/unfiltered`,
    APPROVERS: '/travel-approval/request/get/approvers',
    APPROVAL_TEMPLATES: (schemeId: string) => `/travel-approval/template-request/${schemeId}`,
    REQUEST_UPDATE: (requestId: number, approvalId: number) => `/travel-approval/request/${requestId}/${approvalId}`,
    EXISTS: (id: string) => `/travel-approval/schemes/${id}/exists`,
    GET_ADMIN: '/user-service/users/admin',
    VALIDATE_REQUEST: (reqId: string, cartId: string) => `/request/${reqId}/cart/${cartId}/validate`,
    ASSIGN_REQUEST: (reqId: string, cartId: string) => `/request/${reqId}/cart/${cartId}/assign`,
    APPROVE_REQUEST: '/approve/request',
    DELETE_REQUEST: (id: string) => `/travel-approval/request/${id}`,
    GET_CART_SCHEME_TRAVEL_APPROVAL: (cartId: string, requestId: string) =>
      `/travel-approval/cart/${cartId}/request/${requestId}/approvers`,
    GET_CART_SCHEME: (cartId: string) => `/travel-approval/schemes/cart/${cartId}/approvers`,
    UPDATE_EMPLOYEE_PROJECT: (requestId: number, employeeId: number) =>
      `/travel-approval/request/${requestId}/employee/${employeeId}/project`,
  },
  TAXI: {
    TO_CARD: '/taxi/cart/voucher',
    GET_ACTIVE_VOUCHERS: '/taxi-service/display/vouchers/active',
    GET_CREATED_VOUCHERS: '/taxi-service/display/vouchers/created',
    GET_TEXT_WARNING: '/informant/active/taxi',
    GET_REFUND: (tripItemId: string) => `/taxi/${tripItemId}/refund`,
    CANCEL: (tripItemId: string) => `/taxi/${tripItemId}/cancel`,
  },
  CHARTS_ANALYTICS: {
    GET_GENERAL: '/trip-analytics/general',
    GET_SMART_SUMMARY: '/trip-analytics/smart/summary',
    GET_SMART_SERVICE_TYPE: (serviceType: string, smartAnalyticsType: string) =>
      `/trip-analytics/smart/${serviceType}/${smartAnalyticsType}`,
    GET_SMART_AIRLINE: '/trip-analytics/smart/air',
    GET_SMART_TRAIN: '/trip-analytics/smart/train',
    GET_SMART_AEROEXPRESS: '/trip-analytics/smart/aeroexpress',
    GET_SMART_MICE: '/trip-analytics/smart/mice',
    GET_SMART_TRANSFER: '/trip-analytics/smart/transfer',
    GET_SMART_HOTEL: '/trip-analytics/smart/hotel',
    GET_SMART_TAXI: '/trip-analytics/smart/TaxiVoucher',
    GET_SMART_PAGE_WITH_TRIPS: (serviceType: string, smartAnalyticsType: string) =>
      `/trip-analytics/smart/${serviceType}/${smartAnalyticsType}/subpage`,
    GET_AIRLINE_SUMMARY: '/trip-analytics/air/summary',
    GET_AIRLINE_SUMMARY_SUBPAGE: '/trip-analytics/air/summary/subpage',
    GET_AIRLINE_DIRECTIONS: '/trip-analytics/air/directions',
    GET_AIRLINE_DIRECTIONS_SUBPAGE: '/trip-analytics/air/directions/subpage',
    GET_AIRLINE_COMPANIES: '/trip-analytics/air/companies',
    GET_AIRLINE_COMPANIES_SUBPAGE: '/trip-analytics/air/companies/subpage',
    GET_AIRLINE_EARLINESS: '/trip-analytics/air/earliness',
    GET_AIRLINE_EARLINESS_SUBPAGE: '/trip-analytics/air/earliness/subpage',
    GET_TRAIN_SUMMARY: '/trip-analytics/train/summary',
    GET_TRAIN_SUMMARY_SUBPAGE: '/trip-analytics/train/summary/subpage',
    GET_TRAIN_DIRECTIONS: '/trip-analytics/train/directions',
    GET_TRAIN_DIRECTIONS_SUBPAGE: '/trip-analytics/train/directions/subpage',
    GET_AEROEXPRESS_SUMMARY: '/trip-analytics/aeroexpress/summary',
    GET_AEROEXPRESS_SUMMARY_SUBPAGE: '/trip-analytics/aeroexpress/summary/subpage',
    GET_AEROEXPRESS_DIRECTIONS: '/trip-analytics/aeroexpress/directions',
    GET_AEROEXPRESS_DIRECTIONS_SUBPAGE: '/trip-analytics/aeroexpress/directions/subpage',
    GET_HOTEL_SUMMARY: '/trip-analytics/hotel/summary',
    GET_HOTEL_SUMMARY_SUBPAGE: '/trip-analytics/hotel/summary/subpage',
    GET_HOTEL_DIRECTIONS: '/trip-analytics/hotel/directions',
    GET_HOTEL_DIRECTIONS_SUBPAGE: '/trip-analytics/hotel/directions/subpage',
    GET_HOTEL_POPULAR: '/trip-analytics/hotel/popular',
    GET_HOTEL_POPULAR_SUBPAGE: '/trip-analytics/hotel/popular/subpage',
    GET_TAXI_SUMMARY: '/trip-analytics/taxivoucher/summary',
    GET_TAXI_SUMMARY_SUBPAGE: (type: string) => `/trip-analytics/${type}/summary/subpage`,
    GET_TAXI_SUBPAGE_VOUCHER: '/trip-analytics/TaxiOperation',
    GET_TAXI_DIRECTIONS: '/trip-analytics/taxivoucher/directions',
    GET_TAXI_DIRECTIONS_SUBPAGE: (type: string) => `/trip-analytics/${type}/directions/subpage`,
    GET_TRANSFER_SUMMARY: '/trip-analytics/transfer/summary',
    GET_TRANSFER_SUMMARY_SUBPAGE: '/trip-analytics/transfer/summary/subpage',
    GET_TRANSFER_DIRECTIONS: '/trip-analytics/transfer/directions',
    GET_TRANSFER_DIRECTIONS_SUBPAGE: '/trip-analytics/transfer/directions/subpage',
    GET_MICE_SUMMARY: '/trip-analytics/mice/summary',
    GET_MICE_SUMMARY_SUBPAGE: '/trip-analytics/mice/summary/subpage',
    GET_MICE_DIRECTIONS: '/trip-analytics/mice/directions',
    GET_MICE_DIRECTIONS_SUBPAGE: '/trip-analytics/mice/directions/subpage',
    GET_STRUCTURE_DEPARTMENTS: '/trip-analytics/spendings/departments',
    GET_STRUCTURE_PROJECTS: '/trip-analytics/spendings/projects',
    GET_STRUCTURE_SERVICE_TYPE: '/trip-analytics/spendings/service-type',
    GET_STRUCTURE_CUSTOM_ANALYTICS: (id: string) => `/trip-analytics/spendings/analytics/${id}`,
    GET_STRUCTURE_SERVICE_TYPE_SUBPAGE: '/trip-analytics/spendings/service-type/subpage',
    GET_EMPLOYEES: '/trip-analytics/autocomplete/employee',
    GET_DEPARTMENTS: '/trip-analytics/autocomplete/department',
    GET_PROJECTS: '/trip-analytics/autocomplete/project',
    GET_TAGS: '/trip-analytics/autocomplete/tags',
    GET_ANALYTICS_SMARTAGENT: '/trip-analytics/spendings/smartagent',
    GET_COMPANIES: '/account/companies',
    DOWNLOADS: {
      AIRLINE: {
        SUMMARY: '/trip-analytics/air/summary/download',
        SUMMARY_NESTED: '/trip-analytics/air/summary/subpage/download',
        DIRECTIONS: '/trip-analytics/air/directions/download',
        DIRECTIONS_NESTED: '/trip-analytics/air/directions/subpage/download',
        COMPANIES: '/trip-analytics/air/companies/download',
        COMPANIES_NESTED: '/trip-analytics/air/companies/subpage/download',
        EARLINESS: '/trip-analytics/air/earliness/download',
        EARLINESS_NESTED: '/trip-analytics/air/earliness/subpage/download',
      },
      TRAIN: {
        SUMMARY: '/trip-analytics/train/summary/download',
        SUMMARY_NESTED: '/trip-analytics/train/summary/subpage/download',
        DIRECTIONS: '/trip-analytics/train/directions/download',
        DIRECTIONS_NESTED: '/trip-analytics/train/directions/subpage/download',
      },
      HOTEL: {
        SUMMARY: '/trip-analytics/hotel/summary/download',
        SUMMARY_NESTED: '/trip-analytics/hotel/summary/subpage/download',
        DIRECTIONS: '/trip-analytics/hotel/directions/download',
        DIRECTIONS_NESTED: '/trip-analytics/hotel/directions/subpage/download',
        POPULAR: '/trip-analytics/hotel/popular/download',
        POPULAR_NESTED: '/trip-analytics/hotel/popular/subpage/download',
      },
      TAXI: {
        SUMMARY: '/trip-analytics/taxivoucher/summary/download',
        SUMMARY_NESTED: '/trip-analytics/taxivoucher/summary/subpage/download',
        SUMMARY_NESTED_DOUBLE: '/trip-analytics/taxioperation/download',
        DIRECTIONS: '/trip-analytics/taxivoucher/directions/download',
        DIRECTIONS_NESTED: '/trip-analytics/taxivoucher/directions/subpage/download',
        DIRECTIONS_NESTED_DOUBLE: '/trip-analytics/taxioperation/download',
      },
      AEROEXPRESS: {
        SUMMARY: '/trip-analytics/aeroexpress/summary/download',
        SUMMARY_NESTED: '/trip-analytics/aeroexpress/summary/subpage/download',
        DIRECTIONS: '/trip-analytics/aeroexpress/directions/download',
        DIRECTIONS_NESTED: '/trip-analytics/aeroexpress/directions/subpage/download',
      },
      TRANSFER: {
        SUMMARY: '/trip-analytics/transfer/summary/download',
        SUMMARY_NESTED: '/trip-analytics/transfer/summary/subpage/download',
        DIRECTIONS: '/trip-analytics/transfer/directions/download',
        DIRECTIONS_NESTED: '/trip-analytics/transfer/directions/subpage/download',
      },
      MICE: {
        SUMMARY: '/trip-analytics/mice/summary/download',
        SUMMARY_NESTED: '/trip-analytics/mice/summary/subpage/download',
        DIRECTIONS: '/trip-analytics/mice/directions/download',
        DIRECTIONS_NESTED: '/trip-analytics/mice/directions/subpage/download',
      },
      STRUCTURE: {
        SERVICE_TYPE: '/trip-analytics/spendings/service-type/download',
        SERVICE_TYPE_NESTED: '/trip-analytics/spendings/service-type/subpage/download',
        DEPARTMENTS: '/trip-analytics/spendings/departments/download',
        DEPARTMENTS_NESTED: '/trip-analytics/spendings/service-type/download',
        DEPARTMENTS_NESTED_SUB: '/trip-analytics/spendings/service-type/subpage/download',
        PROJECTS: '/trip-analytics/spendings/projects/download',
        PROJECTS_NESTED: '/trip-analytics/spendings/service-type/download',
        PROJECTS_NESTED_SUB: '/trip-analytics/spendings/service-type/subpage/download',
        CUSTOM_ANALYTICS: (id: string) => `/trip-analytics/spendings/analytics/${id}/download`,
        CUSTOM_ANALYTICS_NESTED: '/trip-analytics/spendings/service-type/download',
        CUSTOM_ANALYTICS_NESTED_SUB: '/trip-analytics/spendings/service-type/subpage/download',
      },
      SMART: {
        DOWNLOAD: (serviceType: string, type: string) => `/trip-analytics/smart/${serviceType}/${type}/download`,
        DOWNLOAD_SUB: (serviceType: string, type: string) => `/trip-analytics/smart/${serviceType}/${type}/subpage/download`,
      },
    },
  },
  QUALITY: {
    SEND: '/quality-notifier',
    SEND_TO_AGGREGATOR: '/quality-notifier/aggregator',
  },
  MERTICS: {
    SEND: '/product-metrics',
    SEND_HOTELS: '/product-metrics/hotels',
  },
  PRODUCT_ANALYTICS: {
    SEND: '/product-analytics/bulk-insert',
  },
  VOUCHER_AGENT: {
    GET: '/booking-service-documents/voucher/settings',
    SET: '/booking-service-documents/voucher/settings',
    DOWNLOAD_LOGO: '/booking-service-documents/voucher/settings/logo/load',
    GET_PREVIEW: '/booking-service-documents/voucher/settings/preview',
    LOAD_VOUCHER: (serviceType: string) =>
      `/booking-service-documents/voucher/settings/preview/${serviceType}/download`,
    ON_DOWNLOAD_FILE: '/files/file',
  },
  SIMPLE_SIGNATURE: {
    LOAD_SIGNATURE: (employeeId: string) => `/document-aggregator/simple-signature/${employeeId}`,
    GET_IMAGES_SIGNATURE: (employeeId: string) => `/document-aggregator/simple-signature/agreement/${employeeId}`,
    GET_DOCS_SIGNATURE: (employeeId: string, page: number, limit:number, sortType:string) =>
      `/document-aggregator/documents?employeeId=${employeeId}&page=${page}&limit=${limit}&sortField=status&sortType=${sortType}`,
    CREATE_SIMPLE_SIGNATURE: '/document-aggregator/simple-signature/create',
    SEND_SIGNATURE_CODE: '/document-aggregator/simple-signature/create/confirm',
    SEND_SINGING_DOCS: '/document-aggregator/documents/signing',
    SEND_SINGING_DOCS_CONFIRM: '/document-aggregator/documents/signing/confirm',
    DELETE_SIGNATURE: (employeeId: string) => `/document-aggregator/simple-signature/${employeeId}`,
    DOWNLOAD_XLSX: (employeeId: string) => `/document-aggregator/documents/registry/download?employeeId=${employeeId}`,
    DOWNLOAD_DOC: (documentId: string, employeeId: string) => `/document-aggregator/documents/${documentId}/${employeeId}/download`,
    GET_DOCUMENT_IMAGE_SIGNATURE: (documentId: string, employeeId: string) => `/document-aggregator/documents/${documentId}/${employeeId}/preview`,
    SEND_EMAIL: (documentId: string, employeeId: string, email: string) => `/document-aggregator/documents/${documentId}/${employeeId}/send-email?email=${email}`,
  },
  TELEGRAM: {
    GET_LINK: (employeeId: string) => `/telegram-bot/link/${employeeId}`,
    GET_RELATION: (employeeId: string) => `/telegram-bot/is-active/${employeeId}`,
    UNLINK_EMPLOYEE: (employeeId: string) => `/telegram-bot/unlink/${employeeId}`,
    SEND_MAIL: (employeeId: number, email: string) => `/telegram-bot/link/${employeeId}/to-email/${email}`,
  },
  EVENT: {
    GET_EVENTS: '/trip-service/mice/event/filtered',
    GET_EVENT: (id: string) => `/trip-service/mice/event/${id}`,
    CREATE_EVENT: '/trip-service/mice/event/add',
    RENAME_EVENT: '/trip-service/mice/event/rename',
    LINK_APPLICATION: '/trip-service/mice/cart/link',
  },
  BOOKING: {
    START: (cartId:string, CART_TYPE: string, paymentMethod: string) => `/booking-service/booking/${cartId}/${CART_TYPE}/start?paymentSource=${paymentMethod}`,
    CONTINUE: (cartId: string, CART_TYPE: string) => `/booking-service/booking/${cartId}/${CART_TYPE}/continue`,
    STATUS: (bookId: string) => `/booking-service/booking/${bookId}/status`,
    CANCEL: (cartId: string, CART_TYPE: string) => `/booking-service/booking/${cartId}/${CART_TYPE}/cancel`,
  },
};

export default ROUTES;
