import { Moment } from 'moment';
import debounce from '../../api/debounce';
import {
  Amplitude,
  MixPanel,
  MainAnalytic,
} from '../../utils/analytics';
import { formatDate, momentObjectUTC } from '../../utils/formatDate';
import toDecline from '../../utils/toDecline';
import parseJsonString from '../../utils/parseJsonString';
import getGuid from '../../utils/guid';
import { roomCountFinder } from '../../utils/hotel';
import { getBooleanFromStringBoolean } from '../../utils/strings';
import { parseSearchString } from '../../utils/convertSearchParams';
import { distanceToAreaMoreRegionRadius, getDistanceToArea } from '../../utils/gis';

import CONFIG from '../../../../config';
import { getText, getTextArray } from '../../../../i18n';

import ACTION from './action';
import SearchStore from './store/searchStore';
import HotelsStore from './store/hotelsStore';
import HotelStore from './store/hotelStore';
import HistoryStore from './store/historyStore';
import ItemsStore from './store/itemsStore';
import SchemeHotelStore from './store/schemeHotelStore';
import { UiSettingsStore } from '../uiSettings/store';
import { ContractHotelStore, ContractHotelStoreType } from './store/contractHotelStore';

import { languages } from '../../../../i18n/locales/consts';
import {
  AMENITITESRU,
  TYPERU,
  HOTEL_RADIUS_SEARCH,
} from '../../constants/hotelsSearch';
import {
  STAT_AUTOCOMPLETE_EVENTS,
} from './constants/stats';
import { SEARCH_MENU_TYPES, VIEW_MODE_TYPES } from './constants/searchMenuTypes';
import { DATEFORMATS, PATTERN } from '../../constants/dateFormats';
import { ACTIONS, CONTRACT_MESSAGE, POSTFIXES_ANALYTIC_ACTIONS, SEARCH_TYPES, MEAL_LIST_TYPE } from '../../constants/hotel';
import { METRICS_HOTEL_TYPES } from '../../constants/metrics';
import { HOTELFILTERTYPE } from '../../constants/tagsFilterType';
import { BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../constants/rights';
import { ISearchStore } from './types';

import { Metrics } from '../metriсs';

import { prepareAutocompleteStats } from './analytics';

import getAccountId from '../../utils/getAccountId';

import {
  HotelRatesResponse,
  HotelsRegionResponse,
  InitSearchType,
  ParamsHotelMetrics,
  ParamsMetrics,
  PrepareAutocompleteStatsType,
  RadiusSearchObject,
  HotelAutocompleteItem,
  SearchHotelsResponse,
  GetRadiusSearchObject,
  FavoriteObject,
  SearchByParamsSettings,
  HistoryObj,
  PrepareCustomTime,
  HotelStatic,
  // SearchByHistoryItemParams,
  QueueItem,
  OfflineRoomGroup,
  SearchByParams,
  HotelAutocompleteObj,
  ITag,
  SearchObjectParams,
  SearchObjectItem,
  ToNoteHotel,
  PrepareRate,
  ISearchHotelBody,
  HOTEL_RESPONSE_STATUS,
  IDispatchRadius,
  WarningTemplate,
} from './types';
import { TravelPolicyItem } from '../userSession/types';
import { EmployeesObj } from '../../types/employees';
import { AnalyticSortHotel } from '../../types/hotel';

const DEBOUNCE_TIME = 0;
const DEBOUNCE_TIME_HOTELS = 500;

const SMART_HOTEL_SORT_ITEM = {
  label: getText('services:hotels.sort.smart'),
  value: 'smart_hotel',
  type: null,
  analyticValue: AnalyticSortHotel.recommended_first,
};

const SORT = [
  {
    label: getText('services:hotels.sort.priceUp'),
    value: 'price_up',
    type: null,
    analyticValue: AnalyticSortHotel.cheaper_first,
  },
  {
    label: getText('services:hotels.sort.priceDown'),
    value: 'price_down',
    type: null,
    analyticValue: AnalyticSortHotel.more_expensive_first,
  },
  {
    label: getText('services:hotels.sort.distance'),
    value: 'distance',
    type: 1,
    analyticValue: AnalyticSortHotel.distance_from_center,
  },
];
const MAIN_DATE_FORMAT = PATTERN.YEAR_MONTH_DAY_TIME_WITHOUT_SECONDS;
const SHORT_DATE_FORMAT = PATTERN.YEARMONTHDAY;
const JUST_TIME_DATE_FORMAT = PATTERN.LOCAL_TIME_PATTERN;

const CONTRACT_LABELS = {
  UNSUCCESSFUL_MESSAGE: getText('hotels:contractHotel.unsuccessfulMessage'),
  REQUEST_NOT_FOUND: getText('hotels:contractHotel.requestNotFound'),
};

class Hotels {
  api: any;
  searchStore: any;
  hotelsStore: any;
  hotelStore: any;
  historyStore: any;
  itemsStore: any;
  schemeHotelStore: any;
  uiSettingsStore: any;
  contractHotelStore: ContractHotelStoreType;
  metrics: Metrics;
  xhr: any | null;
  xhrAutocomplete: any | null;
  xhrNewSearch: any | null;
  debounceAutocomplete: any;
  debounceNewSearch: any;
  queue: QueueItem[];
  pendingPromise: boolean;
  isNeedAddQueue: boolean;
  loadRatesRequest: any;
  autocompleteLoadingTimer: ReturnType<typeof setTimeout> | undefined;

  constructor(api: any) {
    this.api = api.hotel;
    this.uiSettingsStore = UiSettingsStore;
    this.searchStore = SearchStore();
    this.hotelsStore = HotelsStore();
    this.hotelStore = HotelStore();
    this.historyStore = HistoryStore();
    this.itemsStore = ItemsStore();
    this.schemeHotelStore = SchemeHotelStore();
    this.contractHotelStore = ContractHotelStore;
    this.metrics = new Metrics(api);

    this.xhr = null;
    this.xhrAutocomplete = null;
    this.xhrNewSearch = null;

    this.debounceNewSearch = debounce(this.api.newSearchLoadByGuid, DEBOUNCE_TIME);
    this.debounceAutocomplete = debounce(this.api.autocomplete, DEBOUNCE_TIME_HOTELS);

    this.queue = [];
    this.pendingPromise = false;
    this.isNeedAddQueue = true;
    this.autocompleteLoadingTimer = undefined;
  }

  enqueue = (fn: () => Promise<HotelsRegionResponse>): Promise<HotelsRegionResponse> => new Promise((resolve, reject) => {
    this.queue.push({ fn, resolve, reject });
    this.dequeue();
  });

  dequeue = async () => {
    if (this.pendingPromise) return false;

    let item = null;

    if (this.queue.length > 1) {
      item = this.queue.pop();
      this.queue = [];
    } else {
      item = this.queue.shift();
    }

    if (!item || !this.isNeedAddQueue) return false;

    try {
      this.pendingPromise = true;

      const res = await item.fn(this);

      this.pendingPromise = false;
      item.resolve(res);

      if (!res?.body?.SearchComplete) {
        this.dequeue();
      } else {
        this.isNeedAddQueue = true;
        this.queue = [];
      }
    } catch (e) {
      this.pendingPromise = false;
      item.reject(e);
    }

    return true;
  };

  getSearchState = () => this.searchStore.getState();

  getHotelsState = () => this.hotelsStore.getState();

  getHotelState = () => this.hotelStore.getState();

  getItemsState = () => this.itemsStore.getState();

  getHistoryState = () => this.historyStore.getState();

  getSchemeHotelStoreState = (): Pick<ISearchStore, 'region'> => this.schemeHotelStore.getState();

  resetSchemeHotelStore = () => this.schemeHotelStore.dispatch({
    type: ACTION.SCHEME_HOTEL_STORE_RESET,
  });

  autocompleteSchemeHotel = (query: string) => {
    if (this.xhrAutocomplete) this.xhrAutocomplete.abort();

    this.xhrAutocomplete = this.debounceAutocomplete(query.trim());

    this.autocompleteLoadingTimer = setTimeout(() => {
      this.schemeHotelStore.dispatch({
        type: ACTION.SCHEME_UPDATE_REGION_SUGGEST,
        payload: {
          query,
          items: [],
          loading: true,
        },
      });
    }, 100);

    this.xhrAutocomplete.then((res: HotelAutocompleteItem[]) => {
      clearTimeout(this.autocompleteLoadingTimer);

      this.schemeHotelStore.dispatch({
        type: ACTION.SCHEME_UPDATE_REGION_SUGGEST,
        payload: {
          query,
          items: res,
          loading: false,
        },
      });
    });
  };

  resetSearchStore = (isWhiteLabel: boolean) => this.setNewSearch(isWhiteLabel);

  setSchemeLoading = (value: boolean) => this.searchStore.dispatch({
    type: ACTION.SCHEME_LOADING,
    value,
  });

  setSearchFromApproveRequest = async (travelApproval: any) => {
    this.setSchemeLoading(true);

    try {
      const res = await this.api.autocomplete(travelApproval.Destinations[0].Name.trim());
      const updateTravelApproval = {
        ...travelApproval,
        Destination: travelApproval.Destinations[0]?.Name.trim() ? res[0] : null,
      };

      this.searchStore.dispatch({
        type: ACTION.APPROVAL_REQUEST_SEARCH,
        payload: updateTravelApproval,
      });
    } catch (e) {}

    this.setSchemeLoading(false);
  };

  getSearchDatesDiff = () => {
    const { checkin, checkout } = this.getSearchState();

    const nightsCount = checkout.diff(checkin, 'days');
    const label = toDecline(nightsCount, getTextArray('services:hotels.nightDeclines'));

    return `${nightsCount} ${label}`;
  };

  getSortValue = () => {
    const result = [];

    const { hasOneSmart } = this.getHotelsState();

    if (hasOneSmart) {
      result.push(SMART_HOTEL_SORT_ITEM);
    }

    return [...result, ...SORT];
  };

  getParamForHotelDetail = () => {
    const {
      checkin,
      checkout,
      adult,
      region,
      guid,
      hotelId,
      customCheckin,
      customCheckout,
      travellersCount,
    } = this.getSearchState();

    return {
      id: region.selected.Id,
      label: region.selected.Name,
      checkin: formatDate(checkin, MAIN_DATE_FORMAT),
      checkout: formatDate(checkout, MAIN_DATE_FORMAT),
      adult,
      guid,
      hotelId,
      customCheckin,
      customCheckout,
      travellersCount,
    };
  };

  getAmenities = (local = languages.ru) => {
    if (local === languages.ru) return AMENITITESRU;

    return null;
  };

  getType = (local = languages.ru): { [key: string]: string } | null => {
    if (local === languages.ru) return TYPERU;

    return null;
  };

  getHistory = () =>
    this.api.getSearchHistory().then((res: HistoryObj[]) => {
      this.historyStore.dispatch({
        type: ACTION.UPDATEHISTORY,
        payload: res,
      });
    });

  getTemplateWarning = () =>
    this.api.getTemplateWarning().then((res: WarningTemplate | null) => {
      this.searchStore.dispatch({
        type: ACTION.TEXT_TEMPLATE_WARNING,
        payload: res?.content?.text || '',
      });
    });

  isRegion = () => {
    const { region } = this.getSearchState();

    return region?.selected?.IsRegion;
  };

  isShowPriceDetails = () => CONFIG.HOTEL.SHOW_PRICE_DETAILS;

  initSearch = (params: InitSearchType) =>
    this.searchStore.dispatch({
      type: ACTION.INITNEWSEARCH,
      payload: params,
    });

  clearStateHotel = () => this.hotelStore.dispatch({ type: ACTION.CLEARHOTEL });

  setImmediateSearch = () => {
    this.searchStore.dispatch({
      type: ACTION.BACKTOSEARCHLIST,
    });
    this.searchStore.dispatch({
      type: ACTION.SETIMMEDIATESEARCH,
    });
  };

  setSearch = (field: string, value: HotelAutocompleteItem | Moment | number | string | boolean | null) =>
    this.searchStore.dispatch({
      type: field,
      payload: value,
    });

  showFormHotelSearchDialog = (room: OfflineRoomGroup | null = null, show = false) =>
    this.hotelStore.dispatch({
      type: ACTION.SHOW_FORM_HOTEL_SEARCH_DIALOG,
      payload: { room, show },
    });

  updateSelectedEmployee = (value: string[], id: number[]) =>
    this.searchStore.dispatch({
      type: ACTION.SELECT_EMPLOYEE,
      payload: { value, id },
    });

  removeEmployee = (value: EmployeesObj) =>
    this.searchStore.dispatch({
      type: ACTION.REMOVE_EMPLOYEE,
      value,
    });

  updateFilterRadius = (value: IDispatchRadius) =>
    this.hotelsStore.dispatch({
      type: ACTION.UPDATE_RADIUS_FILTER,
      payload: value,
    });

  changeloadingRequest = () =>
    this.hotelStore.dispatch({
      type: ACTION.LOADING_REQUEST,
    });

  updateMessageSend = () =>
    this.hotelStore.dispatch({
      type: ACTION.MESSAGE_SEND,
    });

  closeDialogAfterSend = () => {
    this.showFormHotelSearchDialog();
    this.changeloadingRequest();
    this.updateMessageSend();
  };

  toggleSetCacheOnMap = () =>
    this.hotelsStore.dispatch({
      type: ACTION.TOGGLE_SET_CACHE_ON_MAP,
    });

  applyHotelsFilters = (isFiltersHotelsInMicroservice: boolean, field: string, value: any) => {
    const guid: string = this.getSearchState().guid;
    const {
      initRadiusSearchHotels,
      regionLocation: {
        latitude,
        longitude,
        radius,
      },
      coordsPoint,
    } = this.getHotelsState();

    const isRadiusFilter = field === ACTION.UPDATE_RADIUS_FILTER;
    const isDebounce = field === ACTION.UPDATEHOTELNAMEFILTER;

    if (isRadiusFilter && !value.value && initRadiusSearchHotels) {
      return this.searchAfterDeleteRadius(HOTELFILTERTYPE.RADIUS, isFiltersHotelsInMicroservice);
    }

    const centerCoords: number[] = [latitude, longitude];
    const distance = getDistanceToArea(centerCoords, coordsPoint, value);
    const abort = distanceToAreaMoreRegionRadius(distance, radius);

    if (isRadiusFilter && abort) return null;

    return this.loadLastSearch(guid, isFiltersHotelsInMicroservice, isDebounce, false);
  };

  setHotels = (field: string, value: any, isFiltersHotelsInMicroservice?: boolean) => {
    const cacheFilterTags: ITag[] = this.hotelsStore.getState().tags;

    this.hotelsStore.dispatch({
      type: field,
      payload: {
        value,
        isFiltersHotelsInMicroservice,
      },
    });

    if (
      isFiltersHotelsInMicroservice &&
      (field !== ACTION.UPDATE_VISIBLE_HOTEL_ON_MAP) &&
      (field !== ACTION.UPDATECOORDSPOINT)
    ) {
      this.applyHotelsFilters(isFiltersHotelsInMicroservice, field, value);
    }

    switch (field) {
      case ACTION.UPDATE_RECOMMEND_HOTELS: {
        if (value) {
          MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.HOTELS.OPTIONAL_CHOICE_ON);

          break;
        }

        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.HOTELS.OPTIONAL_CHOICE_OFF);

        break;
      }

      case ACTIONS.FILTERSTRAVELPOLICY: {
        const tp: TravelPolicyItem[] = this.hotelsStore.getState().travelPolicyAllList;

        if (tp) {
          const selectedTp = tp.find(item => item.Id === value.value);

          if (selectedTp) {
            MainAnalytic.send(
              MainAnalytic.CATEGORY.HOTELS,
              MainAnalytic.ACTIONS.HOTELS.FILTERSWITCH,
              {
                label: selectedTp.Name,
              },
            );
          }

          if (value.value === ACTIONS.NOTTP) {
            MainAnalytic.send(
              MainAnalytic.CATEGORY.HOTELS,
              MainAnalytic.ACTIONS.HOTELS.FILTERSWITCH,
              {
                label: 'Не задана',
              },
            );
          }
        }

        break;
      }

      case ACTIONS.FILTERSPRICE: {
        const sliderOldTag = cacheFilterTags.find(item => item.key === 'price');

        if (!sliderOldTag) {
          const sliderNewTag = this.hotelsStore.getState().tags.find((item: ITag) => item.key === 'price');

          if (sliderNewTag) {
            MainAnalytic.send(
              MainAnalytic.CATEGORY.HOTELS,
              MainAnalytic.ACTIONS.HOTELS.FILTERSLIDER,
              {
                label: sliderNewTag.name,
              },
            );
          }
        }

        break;
      }

      case ACTIONS.FILTERSSMARTHOTEL:
      case ACTIONS.FILTERSFAVORITE:
      case ACTIONS.FILTERSHASVAT:
      case ACTIONS.FILTER_PRICE_GUARANTEED: {
        if (value) {
          MainAnalytic.send(
            MainAnalytic.CATEGORY.HOTELS,
            MainAnalytic.ACTIONS.HOTELS.FILTERCHECKBOX,
            {
              label: MainAnalytic.LABELS.HOTELS[field],
            },
          );
        }

        break;
      }

      case ACTIONS.UPDATE_BREAKFAST_FILTER:
      case ACTIONS.UPDATE_HAS_CANCELLATION_FILTER:
      case ACTIONS.UPDATE_ONLINE_FILTER: {
        const postfix = value ? POSTFIXES_ANALYTIC_ACTIONS.ON_REGION : POSTFIXES_ANALYTIC_ACTIONS.OFF_REGION;
        MainAnalytic.send(
          MainAnalytic.CATEGORY.HOTELS,
          MainAnalytic.ACTIONS.HOTELS.FILTERCHECKBOX,
          {
            label: MainAnalytic.LABELS.HOTELS[`${field}${postfix}`],
          },
        );

        break;
      }

      case ACTIONS.FILTERSTYPE: {
        if (value.value) {
          const type = this.getType();

          MainAnalytic.send(
            MainAnalytic.CATEGORY.HOTELS,
            MainAnalytic.ACTIONS.HOTELS.FILTERCHECKBOX,
            {
              label: type ? type[value.type] : null,
            },
          );
        }

        break;
      }

      case ACTIONS.FILTERSAMENITIES: {
        if (value.value) {
          const amenities = this.getAmenities();
          MainAnalytic.send(
            MainAnalytic.CATEGORY.HOTELS,
            MainAnalytic.ACTIONS.HOTELS.FILTERCHECKBOX,
            {
              label: amenities ? amenities[value.type] : null,
            },
          );
        }

        break;
      }

      case ACTIONS.FILTERSSTARS: {
        if (value.value) {
          MainAnalytic.send(
            MainAnalytic.CATEGORY.HOTELS,
            MainAnalytic.ACTIONS.HOTELS.FILTERCHECKBOX,
            {
              label: '*'.repeat(value.type),
            },
          );
        }

        break;
      }

      case ACTIONS.SORTING: {
        MainAnalytic.send(
          MainAnalytic.CATEGORY.HOTELS,
          MainAnalytic.ACTIONS.HOTELS.SORTING,
          {
            label: MainAnalytic.LABELS.HOTELS[value],
          },
        );

        break;
      }

      case ACTIONS.UPDATE_RADIUS_FILTER: {
        MainAnalytic.send(
          MainAnalytic.CATEGORY.HOTELS,
          MainAnalytic.ACTIONS.HOTELS.FILTERNUMBERS,
          {
            label: value.custom
              ? MainAnalytic.LABELS.HOTELS.RADIUSFILTERTOPOINT
              : MainAnalytic.LABELS.HOTELS.RADIUSFILTERTOCENTER,
          },
        );

        break;
      }

      case ACTIONS.UPDATECOORDSPOINT: { // TODO его вообще нет(
        if (value.length) {
          MainAnalytic.send(
            MainAnalytic.CATEGORY.HOTELS,
            MainAnalytic.ACTIONS.HOTELS.SETPOINTTOMAP,
          );
        }

        break;
      }
    }
  };

  setHotel = (action: string, value: { filterName: string, value: boolean | number[] }) => {
    this.hotelStore.dispatch({
      type: action,
      payload: value,
    });

    switch (action) {
      case ACTIONS.UPDATEHOTELFILTER: {
        let currentAction = '';
        const postfix = value.value ? POSTFIXES_ANALYTIC_ACTIONS.ON_HOTEL : POSTFIXES_ANALYTIC_ACTIONS.OFF_HOTEL;

        switch (value.filterName) {
          case 'meal': {
            currentAction = ACTIONS.UPDATE_BREAKFAST_FILTER;

            break;
          }

          case 'hasCancellation': {
            currentAction = ACTIONS.UPDATE_HAS_CANCELLATION_FILTER;

            break;
          }

          case 'online': {
            currentAction = ACTIONS.UPDATE_ONLINE_FILTER;

            break;
          }
        }

        MainAnalytic.send(
          MainAnalytic.CATEGORY.HOTELS,
          MainAnalytic.ACTIONS.HOTELS.FILTERCHECKBOX,
          { label: MainAnalytic.LABELS.HOTELS[`${currentAction}${postfix}`] },
        );

        break;
      }
    }
  };

  setViewMode = (field: string, value: VIEW_MODE_TYPES, isDefaultListMode: boolean = false) => {
    this.hotelsStore.dispatch({
      type: field,
      payload: value,
    });

    if (!isDefaultListMode) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.HOTELS.UPDATE_MAP_VIEW, {
        mapChoice: value,
      });
    }
  };

  isAboveDefaultCheckin = (checkin: Moment): boolean => checkin.get('hour') >= 14;

  isBelowDefaultCheckout = (checkout: Moment): boolean => checkout.get('hour') <= 12;

  prepareCustomTime = (
    customCheckin: Moment | null,
    customCheckout: Moment | null,
    aliases: { from: string, to: string } = { from: 'CheckinTime', to: 'CheckoutTime' },
    deletable = false,
  ): PrepareCustomTime => {
    const customParams: { [key: string]: string } = {};

    if (
      deletable
        ? customCheckin && !this.isAboveDefaultCheckin(customCheckin)
        : customCheckin
    ) {
      customParams[aliases.from] = formatDate(customCheckin, JUST_TIME_DATE_FORMAT);
    }

    if (
      deletable
        ? customCheckout &&
          !this.isBelowDefaultCheckout(customCheckout)
        : customCheckout
    ) {
      customParams[aliases.to] = formatDate(customCheckout, JUST_TIME_DATE_FORMAT);
    }

    return customParams;
  };

  setErrorHotelNotFound = () =>
    this.hotelStore.dispatch({ type: ACTION.HOTELNOTFOUND });

  searchByHotelId = async (queryParse: any, isFiltersHotelsInMicroservice: boolean) => {
    const {
      checkin,
      checkout,
      adult,
      guid,
      hotelId,
      addToHistory,
      label,
      fromHistory,
      customCheckin,
      customCheckout,
      travellersCount,
    } = this.getSearchState();

    this.hotelStore.dispatch({ type: ACTION.STARTLOADHOTEL });

    const params = {
      CheckinDate: formatDate(checkin, MAIN_DATE_FORMAT),
      CheckoutDate: formatDate(checkout, MAIN_DATE_FORMAT),
      GuestsCount: adult,
      RoomCount: roomCountFinder(travellersCount, adult),
      TravellersCount: travellersCount,
      Guid: guid,
      addToHistory,
      FromHistory: fromHistory,
      ...this.prepareCustomTime(
        customCheckin,
        customCheckout,
        undefined,
        true,
      ),
    };

    const mixPanelData = {
      type: 'hotel',
      destination: label,
      startDate: params.CheckinDate,
      endDate: params.CheckoutDate,
      people: adult,
    };

    const res: HotelStatic = await this.api.getStatic(hotelId, params);
    const { RegionId, Latitude, Longitude } = res;
    const radiusParams = {
      RegionId: Number(RegionId),
      Latitude,
      Longitude,
      Radius: HOTEL_RADIUS_SEARCH.SMALL,
    };

    this.hotelStore.dispatch({
      type: ACTION.UPDATE_RADIUS_SEARCH_PARAMS,
      payload: radiusParams,
    });

    this.hotelStore.dispatch({
      type: ACTION.LOADHOTEL,
      payload: res,
    });

    this.searchStore.dispatch({
      type: ACTION.INITNEWSEARCH,
      payload: {
        ...queryParse,
        RegionId: queryParse.hotelId,
        RegionName: res.Name,
        hotelId: res.Id,
      },
    });

    await this.loadHotelRates(isFiltersHotelsInMicroservice);

    Amplitude.pushEvent(Amplitude.TYPE.HOTEL.VISITHOTELPAGE, res);
    MixPanel.track('Search', mixPanelData);

    return res;
  };

  // searchByHistoryItem = (item: any, settings: any) => { // TODO NOT USED ??
  //   this.isNeedAddQueue = true;
  //
  //   const { guid } = this.getSearchState();
  //
  //   const params: SearchByHistoryItemParams = {
  //     CheckinDate: item.CheckinDate,
  //     CheckoutDate: item.CheckoutDate,
  //     RegionId: item.Id,
  //     GuestsCount: item.GuestsCount,
  //     Guid: guid,
  //     FromHistory: true,
  //     ...this.prepareCustomTime(item.CheckinTime, item.CheckoutTime),
  //   };
  //
  //   this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.REGION_FIRST_DISPLAY);
  //   this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.REGION_COMPLETE_DISPLAY);
  //
  //   return this.api.search(params).then(() => {
  //     this.hotelsStore.dispatch({
  //       type: ACTION.STARTSEARCH,
  //       payload: settings,
  //     });
  //     this.searchStore.dispatch({
  //       type: ACTION.SETHISTORYINSTANCE,
  //       payload: {
  //         ...{
  //           ...item,
  //           CheckoutTime: params.CheckoutTime,
  //           CheckinTime: params.CheckinTime,
  //         },
  //         guid,
  //       },
  //     });
  //   }).catch(({ error, status }: ({ error: any, status: number })) => {
  //     const metricsParams = this.getParamsMetrics({
  //       guid,
  //       status,
  //       countResult: 0,
  //       searchComplete: false,
  //       error,
  //     });
  //
  //     this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_FIRST_DISPLAY, metricsParams);
  //     this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_COMPLETE_DISPLAY, metricsParams);
  //   });
  // };

  searchByParams = (params: SearchByParams, settings: SearchByParamsSettings, aggregationId: number | null) => {
    this.isNeedAddQueue = true;

    const newGuid = getGuid();
    const {
      date_from,
      date_to,
      region_id,
      placing_type,
      time_from,
      time_to,
      t_count,
      name,
      region_name,
      is_foreign,
      isFiltersHotelsInMicroservice,
    } = params;

    const travellersCount = Number(t_count);
    const regionId = Number(region_id);
    const guestCount = Number(placing_type);
    const searchParams = {
      CheckinDate: date_from,
      CheckoutDate: date_to,
      RegionId: regionId,
      GuestsCount: guestCount,
      RoomCount: roomCountFinder(
        travellersCount,
        Number(placing_type),
      ),
      TravellersCount: travellersCount,
      CheckinTime: time_from,
      CheckoutTime: time_to,
      Guid: newGuid,
      FromHistory: false,
      IsForeign: getBooleanFromStringBoolean(is_foreign),
      NewSearch: isFiltersHotelsInMicroservice,
    };

    this.searchStore.dispatch({
      type: ACTION.SETHISTORYINSTANCE,
      payload: {
        ...searchParams,
        Name: region_name,
        Id: searchParams.RegionId,
        IsRegion: true,
        guid: newGuid,
        TravellersCount: travellersCount,
      },
    });

    this.hotelsStore.dispatch({
      type: ACTION.STARTSEARCH,
      payload: settings,
      filters: {
        hotelName: name,
      },
      favorite: {
        name,
        dates: {
          from: date_from,
          to: date_to,
        },
      },
      isWhiteLabel: aggregationId !== null,
    });

    this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.REGION_FIRST_DISPLAY);
    this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.REGION_COMPLETE_DISPLAY);

    if (this.xhr) {
      this.xhr.abort();
    }

    this.xhr = this.api.search(searchParams);

    this.xhr.then((res: SearchHotelsResponse) => {
      this.hotelsStore.dispatch({
        type: ACTION.REGION_COMPLETE,
        regionLocation: {
          latitude: res.body.Latitude,
          longitude: res.body.Longitude,
          radius: res.body.Radius,
        },
      });

      this.setLastHotelsSearch(SEARCH_TYPES.REGION);
    }).catch(({ error, status }: ({ error: any, status: number })) => {
      const metricsParams = this.getParamsMetrics({
        guid: newGuid,
        status,
        countResult: 0,
        searchComplete: false,
        error,
      });

      this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_FIRST_DISPLAY, metricsParams);
      this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_COMPLETE_DISPLAY, metricsParams);

      this.hotelsStore.dispatch({
        type: ACTION.REGION_FAIL,
      });
    });
  };

  searchFromRequest = (requestItem: any, settings: any, aggregationId: number | null, isFiltersHotelsInMicroservice: boolean) => {
    this.isNeedAddQueue = true;

    const { SearchOptions } = requestItem;
    const { guid } = this.getSearchState();

    const searchParams = {
      CheckinDate: formatDate(
        SearchOptions.CheckinDate,
        SHORT_DATE_FORMAT,
      ),
      CheckoutDate: formatDate(
        SearchOptions.CheckoutDate,
        SHORT_DATE_FORMAT,
      ),
      RegionId: SearchOptions.Code,
      GuestsCount: requestItem.EmployeesNames.length,
      Guid: guid,
      FromHistory: false,
      NewSearch: isFiltersHotelsInMicroservice,
    };

    this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.REGION_FIRST_DISPLAY);
    this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.REGION_COMPLETE_DISPLAY);

    return this.api.search(searchParams).then(() => {
      this.hotelsStore.dispatch({
        type: ACTION.STARTSEARCH,
        payload: settings,
        isWhiteLabel: aggregationId !== null,
      });
      this.searchStore.dispatch({
        type: ACTION.SETHISTORYINSTANCE,
        payload: {
          ...searchParams,
          Name: SearchOptions.Name,
          Id: searchParams.RegionId,
          IsRegion: true,
          guid,
        },
      });

      this.setLastHotelsSearch(SEARCH_TYPES.REGION);
    }).catch(({ error, status }: ({ error: any, status: number })) => {
      const params = this.getParamsMetrics({
        guid,
        status,
        countResult: 0,
        searchComplete: false,
        error,
      });

      this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_FIRST_DISPLAY, params);
      this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_COMPLETE_DISPLAY, params);
    });
  };

  abortSearchByRadius = () => {
    if (this.xhr) {
      this.xhr.abort();
      this.hotelsStore.dispatch({
        type: ACTION.ABORTRADIUSSEARCH,
        payload: true,
      });
      this.searchStore.dispatch({
        type: ACTION.SET_GUID,
        payload: {
          guid: getGuid(),
        },
      });
      MainAnalytic.send(
        MainAnalytic.CATEGORY.HOTELS,
        MainAnalytic.ACTIONS.HOTELS.SEARCHBYRADIUSABORT,
      );
    }
  };

  searchByRadiusHotelsResult = (isFiltersHotelsInMicroservice: boolean) => {
    this.isNeedAddQueue = true;

    const newGuid = getGuid();
    const {
      regionLocation: {
        latitude,
        longitude,
        radius,
      },
    } = this.getHotelsState();

    const {
      checkin,
      checkout,
      customCheckin,
      customCheckout,
      adult,
      travellersCount,
      region: {
        selected: { Id },
      },
    } = this.getSearchState();

    const searchParams = {
      CheckinDate: formatDate(checkin, PATTERN.YEARMONTHDAY),
      CheckoutDate: formatDate(checkout, PATTERN.YEARMONTHDAY),
      CheckinTime: customCheckin,
      CheckoutTime: customCheckout,
      RoomCount: roomCountFinder(travellersCount, adult),
      Guid: newGuid,
      GuestsCount: adult,
      TravellersCount: travellersCount,
      RegionId: Id,
      Latitude: latitude,
      Longitude: longitude,
      Radius: radius * 2,
      NewSearch: isFiltersHotelsInMicroservice,
    };

    this.searchStore.dispatch({
      type: ACTION.SET_GUID,
      payload: {
        guid: newGuid,
      },
    });

    this.api.radiusSearch(searchParams);
    this.setLastHotelsSearch(SEARCH_TYPES.RADIUS);
  };

  searchByRadiusHotelResult = (isFiltersHotelsInMicroservice: boolean) => {
    this.isNeedAddQueue = true;

    const newGuid = getGuid();
    const {
      radiusSearchParams: {
        RegionId,
        Latitude,
        Longitude,
        Radius,
      },
    } = this.getHotelState();

    const {
      checkin,
      checkout,
      adult,
      travellersCount,
      redirectSearchId,
      redirectSearchType,
    } = this.getSearchState();

    const searchParams = {
      CheckinDate: formatDate(checkin, PATTERN.YEARMONTHDAY),
      CheckoutDate: formatDate(checkout, PATTERN.YEARMONTHDAY),
      RoomCount: roomCountFinder(travellersCount, adult),
      Guid: newGuid,
      GuestsCount: adult,
      TravellersCount: travellersCount,
      RegionId,
      Latitude,
      Longitude,
      Radius,
      RedirectSearchId: redirectSearchId,
      RedirectSearchType: redirectSearchType,
      NewSearch: isFiltersHotelsInMicroservice,
    };

    if (this.xhr) {
      this.xhr.abort();
    }

    this.hotelStore.dispatch({
      type: ACTION.START_RADIUS_SEARCH_HOTEL,
    });

    this.searchStore.dispatch({
      type: ACTION.SET_GUID,
      payload: {
        guid: newGuid,
      },
    });

    this.searchStore.dispatch({
      type: ACTION.UPDATEREGIONSETTINGS,
      payload: {
        RedirectSearchId: newGuid,
        RedirectSearchType: 'radius',
      },
    });

    if (searchParams.Latitude && searchParams.Longitude) {
      this.api.radiusSearch(searchParams);
      this.setLastHotelsSearch(SEARCH_TYPES.RADIUS);
    }
  };

  searchByRadius = (params: RadiusSearchObject, settings: SearchByParamsSettings, isFiltersHotelsInMicroservice: boolean) => {
    this.isNeedAddQueue = true;

    const newGuid = getGuid();
    const {
      date_from,
      date_to,
      region_id,
      placing_type,
      time_from,
      time_to,
      t_count,
      name,
      region_name,
      coords,
      radius,
    } = params;

    const travellersCount = Number(t_count);
    const regionId = Number(region_id);
    const guestCount = Number(placing_type);
    const [latitude, longitude] = coords;
    const searchParams = {
      CheckinDate: date_from,
      CheckinTime: time_from,
      CheckoutDate: date_to,
      CheckoutTime: time_to,
      RegionId: regionId,
      GuestsCount: guestCount,
      RoomCount: roomCountFinder(
        travellersCount,
        Number(placing_type),
      ),
      TravellersCount: travellersCount,
      Guid: newGuid,
      Latitude: latitude,
      Longitude: longitude,
      Radius: radius,
      NewSearch: isFiltersHotelsInMicroservice,
    };

    if (this.xhr) {
      this.xhr.abort();
    }

    if (isFiltersHotelsInMicroservice) {
      this.searchStore.dispatch({
        type: ACTION.SET_GUID,
        payload: {
          guid: newGuid,
        },
      });

      this.setInitRadiusSearchHotels(true);
    }

    this.xhr = this.api.radiusSearch(searchParams);

    this.xhr.then(() => {
      this.hotelsStore.dispatch({
        type: ACTION.STARTRADIUSSEARCH,
        payload: settings,
        favorite: {
          name,
          dates: {
            from: date_from,
            to: date_to,
          },
        },
      });
      this.searchStore.dispatch({
        type: ACTION.SETHISTORYINSTANCE,
        payload: {
          ...searchParams,
          Name: region_name,
          Id: searchParams.RegionId,
          IsRegion: true,
          guid: newGuid,
          TravellersCount: travellersCount,
        },
      });

      this.setLastHotelsSearch(SEARCH_TYPES.RADIUS);

      MainAnalytic.send(
        MainAnalytic.CATEGORY.HOTELS,
        MainAnalytic.ACTIONS.HOTELS.SEARCHBYRADIUS,
      );
    });
  };

  setLastHotelsSearch = (type: SEARCH_TYPES) =>
    this.hotelsStore.dispatch({
      type: ACTION.SET_LAST_HOTELS_SEARCH,
      payload: type,
    });

  addStaticHotel = (classificatorId: number | string) =>
    this.api.getStaticForce(classificatorId).then((hotel: HotelStatic) =>
      this.itemsStore.dispatch({
        type: ACTION.ADDSTATICHOTEL,
        payload: hotel,
      }),
    );

  setHotelItemsLoading = (value: boolean) =>
    this.itemsStore.dispatch({
      type: ACTION.LOADINGSTATICHOTEL,
      payload: value,
    });

  wrapHotelItemsRequest = async (id: number) => {
    try {
      return this.api.getStaticForce(id);
    } catch {}

    return [];
  };

  loadHotelItemsForCart = async (items: any) => {
    // @ts-ignore
    const promises = items.map(({ Data }) => {
      const {
        hotel: { ClassificatorId },
      } = parseJsonString(Data);

      return this.wrapHotelItemsRequest(ClassificatorId);
    });

    const hotelItems = await Promise.all(promises);

    hotelItems.forEach(hotel =>
      this.itemsStore.dispatch({
        type: ACTION.ADDSTATICHOTEL,
        payload: hotel,
      }),
    );
  };

  removeStaticHotel = (classificatorId: number) =>
    this.itemsStore.dispatch({
      type: ACTION.REMOVESTATICHOTEL,
      payload: classificatorId,
    });

  addToFavoriteStaticHotel = (classificatorId: number, favoriteId: string | number) =>
    this.itemsStore.dispatch({
      type: ACTION.ADDTOFAVORITESTATICHOTEL,
      payload: {
        classificatorId,
        favoriteId,
      },
    });

  removeFromFavoriteStaticHotel = (classificatorId: number) =>
    this.itemsStore.dispatch({
      type: ACTION.REMOVEFROMFAVORITESTATICHOTEL,
      payload: classificatorId,
    });

  setNewSearch = (isWhiteLabel?: boolean) => {
    this.searchStore.dispatch({
      type: ACTION.NEWSEARCH,
    });
    this.hotelsStore.dispatch({
      type: ACTION.NEWSEARCH,
      isWhiteLabel,
    });
  };

  autocomplete = (query: string) => {
    this.searchStore.dispatch({
      type: ACTION.UPDATE_REGION_SUGGEST_LOADING,
      payload: {
        loading: true,
      },
    });

    if (this.xhrAutocomplete) this.xhrAutocomplete.abort();

    if (this.autocompleteLoadingTimer) clearTimeout(this.autocompleteLoadingTimer);

    this.xhrAutocomplete = this.debounceAutocomplete(query.trim());

    this.autocompleteLoadingTimer = setTimeout(() => {
      this.searchStore.dispatch({
        type: ACTION.UPDATEREGIONSUGGEST,
        payload: {
          query,
          items: [],
          loading: true,
        },
      });
    }, 1000);

    this.xhrAutocomplete.then((res: HotelAutocompleteItem[]) => {
      clearTimeout(this.autocompleteLoadingTimer);

      this.searchStore.dispatch({
        type: ACTION.UPDATEREGIONSUGGEST,
        payload: {
          query,
          items: res,
          loading: false,
        },
      });

      if (!res) {
        this.saveAutocompleteStats(
          query,
          res,
          STAT_AUTOCOMPLETE_EVENTS.EMPTY.TYPE,
        );
      }
    });
  };

  saveAutocompleteStats = (
    query: string,
    suggests: HotelAutocompleteObj[],
    type: string,
    selected = null,
  ) => {
    const stats: PrepareAutocompleteStatsType = prepareAutocompleteStats(
      query,
      suggests,
      type,
      selected,
    );

    this.api.saveAutocompleteStats(stats);
  };

  getLabelMeal = (Refundable: boolean, { Name, Included }: { Name?: string, Included: boolean }) => {
    const hasMealName = Name
      ? `${MainAnalytic.LABELS.HOTELS.HASMEALNAME}: ${Name}`
      : MainAnalytic.LABELS.HOTELS.HASMEAL;
    const noHasMealName = Name
      ? MainAnalytic.LABELS.HOTELS.NOHASMEALNAME
      : MainAnalytic.LABELS.HOTELS.NOHASMEAL;

    const start = Refundable
      ? MainAnalytic.LABELS.HOTELS.HASCANCELLATION
      : MainAnalytic.LABELS.HOTELS.NOHASCANCELLATION;
    const end = Included
      ? hasMealName
      : noHasMealName;

    const label = `${start} ${end}`;

    return label;
  };

  addToCart = (rate: PrepareRate, requestItemId?: number) => {
    const {
      CancellationPolicy: { Refundable },
      Meal,
      RateId,
      TripId,
      // @ts-ignore
      Select: { count },
      ReservedCount,
    } = rate;
    const { hotel } = this.getHotelState();

    return this.api
      .addToCart(RateId, count, hotel.SearchId, requestItemId, TripId)
      .then(() => {
        Amplitude.pushEvent(Amplitude.TYPE.HOTEL.ADDTOCART);
        this.hotelStore.dispatch({
          type: ACTION.UPDATEROOMCOUNT,
          payload: { RateId, count },
        });

        MainAnalytic.send(
          MainAnalytic.CATEGORY.HOTELS,
          MainAnalytic.ACTIONS.HOTELS.ADDTOCART,
          {
            label: this.getLabelMeal(Refundable, Meal),
            value: ReservedCount,
          },
        );
      });
  };

  addToNote = ({
    Meal,
    CancellationPolicy: { Refundable },
    RateId,
    Select: { count },
    TripId = '',
  }: ToNoteHotel) => {
    const { hotel } = this.getHotelState();

    return this.api
      .addToNote(RateId, count, hotel.SearchId, TripId)
      .then((res: any) => {
        Amplitude.pushEvent(Amplitude.TYPE.HOTEL.ADDTONOTEPAD, res);
        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.HOTEL_SEARCH_TO_NOTE);
        MainAnalytic.send(
          MainAnalytic.CATEGORY.HOTELS,
          MainAnalytic.ACTIONS.HOTELS.ADDTONOTE,
          {
            label: this.getLabelMeal(Refundable, Meal),
            value: count,
          },
        );

        return res;
      });
  };

  addToFavorite = (hotel: any) => {
    const params = {
      Address: hotel.Address,
      Amenities: {
        ...hotel.Amenities,
      },
      ClassificatorId: hotel.ClassificatorId,
      DistanceFromCenter: hotel.DistanceFromCenter,
      IsSmartHotel: hotel.IsSmartHotel,
      Latitude: hotel.Latitude,
      Longitude: hotel.Longitude,
      MainImageUrl: hotel.MainImageUrl,
      Name: hotel.Name,
      RegionId: parseInt(hotel.RegionId, 10),
      Stars: hotel.Stars,
      Type: hotel.Type,
    };

    return this.api.addToFavorite(params);
  };

  setIsRefund = (isRefund: boolean) => this.hotelsStore.dispatch({
    type: ACTION.SET_IS_REFUND,
    payload: isRefund,
  });

  searchAfterDeleteRadius = (tagName: string, isFiltersHotelsInMicroservice: boolean) => {
    const guid: string = this.getSearchState().guid;
    const {
      initRadiusSearchHotels,
    } = this.getHotelsState();

    if (tagName === HOTELFILTERTYPE.RADIUS && initRadiusSearchHotels) {
      this.setIsRefund(true);
      this.startRefind(SEARCH_TYPES.REGION, isFiltersHotelsInMicroservice);
      this.toggleSetCacheOnMap();

      this.hotelsStore.dispatch({
        type: ACTION.ABORTRADIUSSEARCH,
        payload: false,
      });

      return;
    }

    this.loadLastSearch(guid, isFiltersHotelsInMicroservice, false, false);
    this.toggleSetCacheOnMap();
  };

  deleteTag = (tag: ITag, isFiltersHotelsInMicroservice: boolean) => {
    const { region } = this.getSearchState();

    this.hotelsStore.dispatch({
      type: ACTION.DELETE_TAGS,
      payload: {
        tag,
        isFiltersHotelsInMicroservice,
        region,
      },
    });

    if (isFiltersHotelsInMicroservice) {
      this.searchAfterDeleteRadius(tag.filter, isFiltersHotelsInMicroservice);
    }
  };

  setInitRadiusSearchHotels = (value: boolean) =>
    this.hotelsStore.dispatch({
      type: ACTION.INIT_RADIUS_SEARCH_HOTELS,
      payload: value,
    });

  /**
   * Ф-я для перепоиска
   * @param {*} lastHotelsSearch - Какой перепоиск запускать (по радиусу или по региону).
   */
  startRefind = (lastHotelsSearch: SEARCH_TYPES, isFiltersHotelsInMicroservice: boolean) => {
    const {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    } = this.getHotelsState();

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    if (lastHotelsSearch === SEARCH_TYPES.RADIUS) {
      return this.searchByRadius(
        this.mapStateToRadiusSearchObject(isFiltersHotelsInMicroservice),
        settings,
        isFiltersHotelsInMicroservice,
      );
    }

    const params = {
      ...parseSearchString(document.location.search),
      isFiltersHotelsInMicroservice,
    } as SearchByParams;

    this.searchByParams(params, settings, this.uiSettingsStore.aggregationId);

    return this.setInitRadiusSearchHotels(false);
  };

  /**
   * Функция отправляет запрос на бек, на получение отелей при поиске.
   * @async
   * @param {*} isDebounce - Что бы не было лишних запросов на бек. Пока работает с фильтром по названию отеля.
   * @param {*} isFiltersHotelsInMicroservice - Если флаг true, то работает поиск новой выдачи.
   * @param {*} isNeedEnqueue - Если требуется запуск сигналлера при поиске.
  */
  loadLastSearch = async (
    refreshGuid: string,
    isFiltersHotelsInMicroservice: boolean,
    isDebounce: boolean = false,
    isNeedEnqueue: boolean = true,
  ) => {
    const guid: string = this.getSearchState().guid;
    const { radiusSearchHotelResult } = this.getHotelState();
    const {
      hotelsFilters,
      lastHotelsSearch,
      accountTravelPolicy,
      rightsBuyTrip: {
        BuyTripAccount,
        BuyTripPersonal,
      },
    } = this.getHotelsState();
    const unavailableTravelPolicy =
      BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable &&
      BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;
    const currentTP = accountTravelPolicy && unavailableTravelPolicy
      ? accountTravelPolicy.Id
      : hotelsFilters.Rate.TravelPolicy;
    const { Meal } = hotelsFilters.Rate;

    const meal = Meal.length > 0 ? MEAL_LIST_TYPE.find(({ list }) =>
      list.length === Meal.length && list.every(num => Meal.includes(num)))?.value : [];

    const dataSearch = {
      guid,
      data: {
        ...hotelsFilters,
        Rate: {
          ...hotelsFilters.Rate,
          TravelPolicy: currentTP,
          Meal: meal,
        },
      },
    };

    const getResponse = () => {
      if (isNeedEnqueue) {
        // поиск с сигналлером
        return this.enqueue(() => this.loadByGuid(dataSearch, isFiltersHotelsInMicroservice));
      }

      if (isDebounce) {
        // для фильтра по названию отеля
        if (this.xhrNewSearch) this.xhrNewSearch.abort();

        this.xhrNewSearch = this.debounceNewSearch(dataSearch);

        return this.xhrNewSearch;
      }

      // для фильтров
      return this.loadByGuid(dataSearch, isFiltersHotelsInMicroservice);
    };

    if (guid && guid === refreshGuid && this.isNeedAddQueue) {
      try {
        const res: HotelsRegionResponse = await getResponse();

        if (res.body.Status === HOTEL_RESPONSE_STATUS.ERROR_SEARCH && isFiltersHotelsInMicroservice) {
          return this.startRefind(lastHotelsSearch, isFiltersHotelsInMicroservice);
        }

        if (radiusSearchHotelResult) {
          return this.loadHotelsResultPage(guid, refreshGuid, res, isFiltersHotelsInMicroservice);
        }

        return this.loadHotelsSearchPage(guid, refreshGuid, res, isFiltersHotelsInMicroservice);
      } catch (data) {
        // @ts-ignore
        const { error, status } = data;

        return this.rejectHotelsByGuid(guid, error, status);
      }
    }

    return () => {};
  };

  loadHotelsByGuid = (
    guid: string,
    refreshGuid: string,
    dispatch: any,
    loadComplete: any,
    response: HotelsRegionResponse,
    isFiltersHotelsInMicroservice: boolean,
  ) => {
    const currentGuid: string = this.getSearchState().guid;

    if (currentGuid === refreshGuid) {
      dispatch(response, isFiltersHotelsInMicroservice);

      const searchComplete = response?.body?.SearchComplete;

      if (searchComplete && this.metrics.store.hotelsMetrics.RegionCompleteDisplay.active) {
        const params = this.getParamsMetrics({
          guid,
          status: response.status,
          countResult: response.body.Variants?.length,
          searchComplete: true,
          error: '',
        });
        this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_COMPLETE_DISPLAY, params);
      }

      if (searchComplete) {
        loadComplete(refreshGuid, isFiltersHotelsInMicroservice);
      }
    }
  };

  rejectHotelsByGuid = (guid: string, error: any, status: number) => {
    const { sources } = this.getHotelsState();
    const params = this.getParamsMetrics({
      guid,
      status,
      countResult: 0,
      searchComplete: false,
      error,
    });

    if (!sources.length) {
      return this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_FIRST_DISPLAY, params);
    }

    return this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_COMPLETE_DISPLAY, params);
  };

  getParamsMetrics = ({
    guid,
    status,
    countResult = 0,
    searchComplete = false,
    error = '',
  }: ParamsMetrics) => {
    const { regionLocation: { latitude, longitude, radius } } = this.getHotelsState();
    const {
      checkin,
      checkout,
      customCheckin,
      customCheckout,
      region: {
        selected: { Id },
      },
    } = this.getSearchState();

    const checkInDate = customCheckin
      ? formatDate(customCheckin, MAIN_DATE_FORMAT)
      : formatDate(checkin, MAIN_DATE_FORMAT);
    const checkOutDate = customCheckout
      ? formatDate(customCheckout, MAIN_DATE_FORMAT)
      : formatDate(checkout, MAIN_DATE_FORMAT);

    return {
      RegionId: Id,
      SearchId: guid,
      Radius: radius,
      Checkin: momentObjectUTC(checkInDate),
      Checkout: momentObjectUTC(checkOutDate),
      Latitude: latitude,
      Longitude: longitude,
      CountResult: countResult,
      SearchComplete: searchComplete,
      Status: status,
      Error: error,
    };
  };

  getParamsHotelMetrics = ({
    guid,
    hotelId,
    status,
    countResult = 0,
    error = '',
  }: ParamsHotelMetrics) => {
    const {
      checkin,
      checkout,
      customCheckin,
      customCheckout,
    } = this.getSearchState();

    const checkInDate = customCheckin
      ? formatDate(customCheckin, MAIN_DATE_FORMAT)
      : formatDate(checkin, MAIN_DATE_FORMAT);
    const checkOutDate = customCheckout
      ? formatDate(customCheckout, MAIN_DATE_FORMAT)
      : formatDate(checkout, MAIN_DATE_FORMAT);

    return ({
      SearchId: guid,
      Checkin: momentObjectUTC(checkInDate),
      Checkout: momentObjectUTC(checkOutDate),
      HotelId: hotelId,
      CountResult: countResult,
      Status: status,
      Error: error,
    });
  };

  loadHotelsSearchPage = (guid: string, refreshGuid: string, response: HotelsRegionResponse, isFiltersHotelsInMicroservice: boolean) => {
    const updateHotelsDispatch = (res: HotelsRegionResponse) => {
      if (this.metrics.store.hotelsMetrics.RegionFirstDisplay.active && res.body.Variants.length) {
        const params = this.getParamsMetrics({
          guid,
          status: res.status,
          countResult: res.body.Variants.length,
          searchComplete: false,
          error: '',
        });

        this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_FIRST_DISPLAY, params);
      }

      const startMetrics = () => this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.REGION_DISPLAY_PREPARATION);

      const stopMetrics = () => {
        if (res?.body?.SearchComplete && this.metrics.store.hotelsMetrics.RegionDisplayPreparation.active) {
          const params = this.getParamsMetrics({
            guid,
            status: res.status,
            countResult: res.body.Variants.length,
            searchComplete: false,
            error: '',
          });

          this.metrics.endTimeHotels(METRICS_HOTEL_TYPES.REGION_DISPLAY_PREPARATION, params);
        }
      };

      this.hotelsStore.dispatch({
        type: ACTION.UPDATE_HOTELS_SEARCH_PAGE,
        payload: {
          res: res.body,
          isFiltersHotelsInMicroservice,
          startMetrics: res?.body?.SearchComplete ? startMetrics : () => {},
          stopMetrics: res?.body?.SearchComplete ? stopMetrics : () => {},
        },
      });
    };

    this.loadHotelsByGuid(guid, refreshGuid, updateHotelsDispatch, this.loadComplete, response, isFiltersHotelsInMicroservice);
  };

  loadHotelsResultPage = (guid: string, refreshGuid: string, response: HotelsRegionResponse, isFiltersHotelsInMicroservice: boolean) => {
    const updateHotelsDispatch = (res: HotelsRegionResponse) => this.hotelStore.dispatch({
      type: ACTION.UPDATE_HOTELS_RESULT_PAGE,
      payload: {
        body: res.body,
        isFiltersHotelsInMicroservice,
      },
    });

    this.loadHotelsByGuid(guid, refreshGuid, updateHotelsDispatch, this.loadCompleteResultPage, response, isFiltersHotelsInMicroservice);
  };

  loadByGuid = (
    dataSearch: ISearchHotelBody,
    isFiltersHotelsInMicroservice: boolean,
  ): Promise<HotelsRegionResponse> => {
    if (isFiltersHotelsInMicroservice) {
      return this.api.newSearchLoadByGuid(dataSearch);
    }

    return this.api.loadByGuid(dataSearch.guid);
  };

  loadHotelRates = (isFiltersHotelsInMicroservice: boolean) => {
    this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.HOTEL_DISPLAY);

    const {
      checkin,
      checkout,
      adult,
      travellersCount,
      hotelId,
      guid,
      customCheckin,
      customCheckout,
      redirectSearchId,
      redirectSearchType,
      optimalRate,
    } = this.getSearchState();

    this.hotelStore.dispatch({ type: ACTION.STARTLOADHOTELRATE });

    const isCustomCheckIn = !!customCheckin;
    const isCustomCheckOut = !!customCheckout;

    const checkInDate = customCheckin
      ? formatDate(customCheckin, MAIN_DATE_FORMAT)
      : formatDate(checkin, MAIN_DATE_FORMAT);
    const checkOutDate = customCheckout
      ? formatDate(customCheckout, MAIN_DATE_FORMAT)
      : formatDate(checkout, MAIN_DATE_FORMAT);

    const hotelIdNumber = parseInt(hotelId, 10);

    if (this.loadRatesRequest) {
      this.loadRatesRequest.abort();
    }

    const searchParams = {
      SearchId: guid,
      CheckInDate: checkInDate,
      CheckOutDate: checkOutDate,
      NumberOfGuests: parseInt(adult, 10),
      RoomCount: roomCountFinder(travellersCount, adult),
      TravellersCount: travellersCount,
      HotelId: hotelIdNumber,
      CustomCheckIn: isCustomCheckIn,
      CustomCheckOut: isCustomCheckOut,
      RedirectSearchId: redirectSearchId,
      RedirectSearchType: redirectSearchType,
      OptimalRate: optimalRate,
    };
    this.loadRatesRequest = this.api.loadHotelRate(searchParams);

    const stopMetrics = (method: string, status: number, countResult: number, error = '') => {
      const params = this.getParamsHotelMetrics({
        guid,
        hotelId: hotelIdNumber,
        status,
        countResult,
        error,
      });

      this.metrics.endTimeHotels(method, params);
    };

    return this.loadRatesRequest
      .then((res: HotelRatesResponse) => {
        const { body, status } = res;
        const { RoomGroups } = body;

        const countRates = !RoomGroups.length ? 0 : RoomGroups.flatMap(({ Rates }) => Rates).length;

        stopMetrics(METRICS_HOTEL_TYPES.HOTEL_DISPLAY, status, countRates);

        if (!RoomGroups.length && this.getHotelState().radiusSearchParams) {
          this.searchStore.dispatch({
            type: ACTION.UPDATEREGIONSETTINGS,
            payload: {
              RedirectSearchId: body.SearchId,
              RedirectSearchType: 'hotel',
            },
          });

          this.hotelStore.dispatch({
            type: ACTION.UPDATE_HOTEL_IMPORTANT_INFO,
            payload: body.ImportantInfo,
          });

          this.searchByRadiusHotelResult(isFiltersHotelsInMicroservice);
        } else {
          this.hotelStore.dispatch({
            type: ACTION.UPDATEHOTELROOMGROUPS,
            payload: {
              res: body,
              roomCount: roomCountFinder(travellersCount, adult),
              startMetrics: () => this.metrics.startTimeHotels(METRICS_HOTEL_TYPES.HOTEL_DISPLAY_PREPARATION),
              stopMetrics: () => stopMetrics(METRICS_HOTEL_TYPES.HOTEL_DISPLAY_PREPARATION, status, countRates),
            },
          });
        }

        if (!RoomGroups.length) {
          MainAnalytic.send(
            MainAnalytic.CATEGORY.HOTELS,
            MainAnalytic.ACTIONS.HOTELS.NONUMBERS,
            {
              value: Number(hotelId),
            },
          );
        }

        return RoomGroups;
      })
      .catch((res: ({ error: any, status: number })) => {
        stopMetrics(METRICS_HOTEL_TYPES.HOTEL_DISPLAY, res.status, 0, res.error);

        if (
          res.status === 404 ||
          res.status === 500 ||
          res.status === 502
        ) {
          this.hotelStore.dispatch({
            type: ACTION.RATESNOTFOUND,
          });
        }
      });
  };

  loadCompleteResultPage = (refreshGuid: string, isFiltersHotelsInMicroservice: boolean) => {
    const guid = this.getSearchState().guid;
    const { hotelsRecommended, radiusSearchParams } = this.getHotelState();

    if (!hotelsRecommended.length && radiusSearchParams.Radius !== HOTEL_RADIUS_SEARCH.BIG && guid === refreshGuid) {
      this.hotelStore.dispatch({
        type: ACTION.UPDATE_RADIUS_SEARCH_PARAMS,
        payload: { Radius: HOTEL_RADIUS_SEARCH.BIG },
      });

      return this.searchByRadiusHotelResult(isFiltersHotelsInMicroservice);
    }

    if (guid === refreshGuid) {
      return this.hotelStore.dispatch({
        type: ACTION.LOAD_COMPLETE_RESULT_PAGE,
      });
    }

    return null;
  };

  loadComplete = (refreshGuid: string, isFiltersHotelsInMicroservice: boolean) => {
    const guid = this.getSearchState().guid;

    if (guid === refreshGuid) {
      const {
        loading,
        sources,
        initDoubleRadiusSearch,
        displayFilters,
        initRadiusSearchHotels,
      } = this.hotelsStore.getState();

      if (!sources.length && !initDoubleRadiusSearch && !displayFilters?.CountHotels && !initRadiusSearchHotels) {
        this.hotelsStore.dispatch({
          type: ACTION.INIT_DOUBLE_RADIUS_SEARCH,
        });

        this.searchByRadiusHotelsResult(isFiltersHotelsInMicroservice);

        return;
      }

      this.hotelsStore.dispatch({
        type: ACTION.LOADCOMPLETE,
      });

      if (!loading && !sources.length) {
        const {
          checkin,
          checkout,
          region,
          adult,
        } = this.searchStore.getState();

        const label = `${region.label},
          ${formatDate(checkin, DATEFORMATS.DATE)}
          -${formatDate(checkout, DATEFORMATS.DATE)}`;
        MainAnalytic.send(
          MainAnalytic.CATEGORY.HOTELS,
          MainAnalytic.ACTIONS.HOTELS.EMPTYREQUEST,
          {
            label,
            value: adult,
          },
        );
      }
    }
  };

  resetFilters = (isFiltersHotelsInMicroservice: boolean, currentRadius: number) => {
    this.hotelsStore.dispatch({
      type: ACTION.RESET_FILTERS,
      payload: isFiltersHotelsInMicroservice,
    });

    if (isFiltersHotelsInMicroservice) {
      const guid: string = this.getSearchState().guid;
      const {
        initRadiusSearchHotels,
        regionLocation: {
          latitude,
          longitude,
          radius,
        },
        coordsPoint,
      } = this.getHotelsState();

      const distance = getDistanceToArea([latitude, longitude], coordsPoint, currentRadius);
      const abort = distanceToAreaMoreRegionRadius(distance, radius);

      if (abort && initRadiusSearchHotels) return;

      this.loadLastSearch(guid, isFiltersHotelsInMicroservice, false, false);
    }
  };

  mapStateToSearchObject = () => {
    const {
      checkin,
      checkout,
      adult,
      region: {
        label,
        selected: { Id, Name },
        isForeign,
      },
      travellersCount,
      customCheckin,
      customCheckout,
    } = this.getSearchState();

    return this.getSearchObject(
      {
        RegionId: Id,
        RegionName: Name || label,
        Name: '',
        IsForeign: isForeign,
      },
      {
        dateFrom: checkin,
        dateTo:
        checkout,
        travellersCount,
        placingType:
        adult,
        timeFrom:
        customCheckin,
        timeTo:
        customCheckout,
      },
    );
  };

  mapStateToRadiusSearchObject = (isFiltersHotelsInMicroservice: boolean): RadiusSearchObject => {
    const {
      checkin,
      checkout,
      adult,
      region: {
        label,
        selected: { Id, Name },
      },
      travellersCount,
      customCheckin,
      customCheckout,
    } = this.getSearchState();

    const {
      filters: { radius },
      coordsPoint,
      regionLocation: {
        latitude,
        longitude,
      },
      hotelsFilters: {
        Hotel: {
          Proximity: {
            Radius,
          },
        },
      },
    } = this.getHotelsState();

    return this.getRadiusSearchObject({
      item: {
        RegionId: Id,
        RegionName: Name || label,
        Name: '',
      },
      params: {
        dateFrom: checkin,
        dateTo: checkout,
        travellersCount,
        placingType: adult,
        timeFrom: customCheckin,
        timeTo: customCheckout,
        coords: coordsPoint.length ? coordsPoint : [latitude, longitude],
        radius: isFiltersHotelsInMicroservice ? +Radius : Number(radius.value),
      },
    });
  };

  prepareCheckinCheckoutParams = (checkin: Moment, checkout: Moment) => {
    const customParams: ({ CheckinTime?: string, CheckoutTime?:string }) = {};

    if (checkin) {
      customParams.CheckinTime = formatDate(checkin, DATEFORMATS.TIME);
    }

    if (checkout) {
      customParams.CheckoutTime = formatDate(checkout, DATEFORMATS.TIME);
    }

    return customParams;
  };

  setSearchHotelFavoriteObject = ({
    timeFrom,
    timeTo,
    dateFrom,
    dateTo,
    placingType,
    travellersCount,
  }: FavoriteObject) => ({
    CheckinDate: formatDate(dateFrom, PATTERN.YEARMONTHDAY),
    CheckoutDate: formatDate(dateTo, PATTERN.YEARMONTHDAY),
    ...this.prepareCustomTime(timeFrom, timeTo, {
      from: 'CheckinTime',
      to: 'CheckoutTime',
    }),
    GuestsCount: placingType,
    TravellersCount: travellersCount,
    RoomCount: roomCountFinder(travellersCount, placingType),
    AddToHistory: false,
    RequestItemId: 0,
  });

  mapStateToHotelPageSearchSettings = (requestItemId?: number) => {
    const {
      checkin,
      checkout,
      adult,
      region: {
        selected: { Id, Name },
      },
      customCheckin,
      customCheckout,
      travellersCount,
    } = this.getSearchState();

    const newRequestItemId = requestItemId
      ? { RequestItemId: requestItemId }
      : {};

    return {
      CheckinDate: formatDate(checkin, PATTERN.YEARMONTHDAY),
      CheckoutDate: formatDate(checkout, PATTERN.YEARMONTHDAY),
      GuestsCount: adult,
      RoomCount: roomCountFinder(travellersCount, adult),
      TravellersCount: travellersCount,
      RegionName: Name,
      RegionId: Id,
      ...this.prepareCheckinCheckoutParams(
        customCheckin,
        customCheckout,
      ),
      AddToHistory: false,
      ...newRequestItemId,
    };
  };

  getSearchObject = (
    item: SearchObjectItem,
    {
      dateFrom,
      dateTo,
      travellersCount,
      placingType,
      timeFrom,
      timeTo,
    }: SearchObjectParams,
  ) => ({
    date_from: formatDate(dateFrom, SHORT_DATE_FORMAT),
    date_to: formatDate(dateTo, SHORT_DATE_FORMAT),
    t_count: travellersCount,
    placing_type: placingType,
    region_id: item.RegionId,
    region_name: item.RegionName,
    name: item.Name,
    is_foreign: item.IsForeign,
    ...this.prepareCustomTime(timeFrom, timeTo, {
      from: 'time_from',
      to: 'time_to',
    }),
  });

  getRadiusSearchObject = ({
    item,
    params: {
      dateFrom,
      dateTo,
      travellersCount,
      placingType,
      timeFrom,
      timeTo,
      coords,
      radius,
    },
  }:GetRadiusSearchObject): RadiusSearchObject => ({
    date_from: formatDate(dateFrom, SHORT_DATE_FORMAT),
    date_to: formatDate(dateTo, SHORT_DATE_FORMAT),
    t_count: travellersCount,
    placing_type: placingType,
    region_id: item.RegionId,
    region_name: item.RegionName,
    name: item.Name,
    coords,
    radius,
    ...this.prepareCustomTime(timeFrom, timeTo, {
      from: 'time_from',
      to: 'time_to',
    }),
  });

  changeFavoriteStatus = (id: number, favoriteId: number | string, headerPart = '') => {
    this.hotelsStore.dispatch({
      type: ACTION.CHANGEFAVORITESTATE,
      payload: {
        id,
        favoriteId,
      },
    });

    if (favoriteId) {
      MainAnalytic.sendHotelFavorite(id, headerPart);
    }
  };

  searchFormIsValid = (searchMenuType = ''): boolean => {
    const {
      region,
      checkin,
      checkout,
      customCheckin,
      customCheckout,
    } = this.getSearchState();

    const startDate = customCheckin || checkin;
    const endDate = customCheckout || checkout;

    const isDatesValid =
      startDate && startDate.isValid() &&
      endDate && endDate.isValid() &&
      endDate.isAfter(startDate);

    if (searchMenuType === SEARCH_MENU_TYPES.DETAILS_MENU) {
      return isDatesValid;
    }

    return region.selected && !!region?.selected?.Id && isDatesValid;
  };

  errorHotelResearch = (value: boolean) => {
    this.hotelStore.dispatch({
      type: ACTION.SET_ERROR_HOTEL_RESEARCH,
      payload: value,
    });
  };

  updateHasVatFilter = (value: boolean) => {
    this.hotelStore.dispatch({
      type: ACTION.UPDATEHASVATFILTER,
      payload: value,
    });

    if (value) {
      MainAnalytic.send(
        MainAnalytic.CATEGORY.HOTELS,
        MainAnalytic.ACTIONS.HOTELS.FILTERNUMBERS,
        {
          label: MainAnalytic.LABELS.HOTELS.TARIFFSWITHVAT,
        },
      );
    }
  };

  updateHotelFavorite = (id: string | null) =>
    this.hotelStore.dispatch({
      type: ACTION.UPDATEHOTELFAVORITE,
      payload: id,
    });

  initHotelFilter = (params: any) =>
    this.hotelStore.dispatch({
      type: ACTION.UPDATEHOTELFILTERS,
      payload: params,
    });

  subscribeSearch = (cb: any) => this.searchStore.subscribe(cb);

  subscribeHotels = (cb: (params: any) => void) => this.hotelsStore.subscribe(cb);

  subscribeHotel = (cb: any) => this.hotelStore.subscribe(cb);

  subscribeHistory = (cb: any) => this.historyStore.subscribe(cb);

  subscribeItems = (cb: any) => this.itemsStore.subscribe(cb);

  subscribeSchemeHotelStore = (cb: any) => this.schemeHotelStore.subscribe(cb);

  get importantInformation() {
    const { ImportantInfo } = this.getHotelState().hotel;

    if (!ImportantInfo || (!ImportantInfo.TaxInfo && !ImportantInfo.AccommodationInfo)) {
      return null;
    }

    return ImportantInfo;
  }

  requestContract = async (action: string, guid: string) => {
    try {
      this.contractHotelStore.setLoading(true);

      await this.api.requestContract(action, guid);

      this.contractHotelStore.setMessage(CONTRACT_MESSAGE[action]);

      this.contractHotelStore.setLoading(false);
    } catch (e: any) {
      const errorMessage = e.status === 404
        ? CONTRACT_LABELS.REQUEST_NOT_FOUND
        : CONTRACT_LABELS.UNSUCCESSFUL_MESSAGE;

      this.contractHotelStore.setMessage(errorMessage);

      this.contractHotelStore.setLoading(false);
    }
  };

  getCountReserved = async () => {
    try {
      const res = await this.api.getCountReserved(getAccountId());

      this.hotelStore.dispatch({
        type: ACTION.CHANGE_RESERVED_COUNT,
        payload: res,
      });
    } catch (e) {
      this.hotelStore.dispatch({
        type: ACTION.CHANGE_RESERVED_COUNT,
        payload: 20,
      });
    }
  };
}

export default Hotels;
