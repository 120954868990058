import React, { useEffect } from 'react';
import { PageLoader, Text, Icon, Tooltip } from 'new-ui';

import { observer } from 'mobx-react';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { IFeeConstructionComponents } from './types';
import { IFeeConstruction } from '../../../../bi/services/settings/types';

import SERVICETYPE from '../../../../bi/constants/serviceType';

import MainAnalytic from '../../../../bi/utils/analytics/main';

import { ConstructionForm } from './components/ConstructionForm';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('settings:feeConstructor:header'),
  SUB_TITLE_FIRST: getText('settings:feeConstructor:subHeader.first'),
  SUB_TITLE_SECOND: getText('settings:feeConstructor:subHeader.second'),
  SUB_TITLE_SUB_SECOND: getText('settings:feeConstructor:subHeader.sub_second'),
  SUB_TITLE_THIRD: getText('settings:feeConstructor:subHeader.third'),
  TOOLTIP: {
    AGENCY_MODE: getText('settings:feeConstructor:subHeader.tooltip.agencyMode'),
    CLIENT_MODE: getText('settings:feeConstructor:subHeader.tooltip.clientMode'),
    FIRST: getText('settings:feeConstructor:subHeader.tooltip.first'),
    SECOND: getText('settings:feeConstructor:subHeader.tooltip.second'),
  },
  FEES_FOR_AIR_TICKETS: getText('settings:feeConstructor:feesForAirTickets'),
  FEES_FOR_TRAIN: getText('settings:feeConstructor:feesForTrain'),
  FEES_FOR_HOTEL: getText('settings:feeConstructor:feesForHotel'),
};

const TYPE_FEE_VALUES = {
  RUB: 'Currency',
  PERCENT: 'Percentages',
};

const TYPE_FEE_VALUE_PROPERTIES = {
  RUB: 'rub',
  PERCENT: 'percent',
};

const FeeConstruction = observer(({
  settingsService: {
    getFeeConstruction,
    updateFeeConstruction,
    deleteFeeConstruction,
  },
}: IFeeConstructionComponents) => {
  const {
    settingsStore: {
      feeConstruction, feeWaitingResponse,
    },
  } = useStores([MOBX_STORES.SETTINGS_STORE]);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.OPENED);
    getFeeConstruction();
  }, [getFeeConstruction]);

  if (feeWaitingResponse) {
    return <PageLoader/>;
  }

  const handleSave = (data: IFeeConstruction, isChangeForm: boolean) => {
    const { ServiceType, AdditionalData, PricingType, Value } = data;

    const preparedPricingType = PricingType === TYPE_FEE_VALUES.RUB
      ? TYPE_FEE_VALUE_PROPERTIES.RUB
      : TYPE_FEE_VALUE_PROPERTIES.PERCENT;

    updateFeeConstruction(data);

    const prepareTaxobject = AdditionalData ? AdditionalData.ApplicationType : null;

    if (Value === 0) {
      return MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SMART_AGENT.DELETE_PRESSED({ service: ServiceType, taxobject: prepareTaxobject, taxtype: preparedPricingType }),
      );
    }

    if (isChangeForm) {
      return MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SMART_AGENT.EDIT_PRESSED({ service: ServiceType, taxobject: prepareTaxobject, taxtype: preparedPricingType }),
      );
    }

    return MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SMART_AGENT.SAVE_PRESSED({ service: ServiceType, taxobject: prepareTaxobject, taxtype: preparedPricingType }),
    );
  };

  const handleDelete = (data: IFeeConstruction) => {
    const { ServiceType, AdditionalData, PricingType } = data;

    const preparedPricingType = PricingType === TYPE_FEE_VALUES.RUB
      ? TYPE_FEE_VALUE_PROPERTIES.RUB
      : TYPE_FEE_VALUE_PROPERTIES.PERCENT;

    deleteFeeConstruction(data);

    const prepareTaxobject = AdditionalData ? AdditionalData.ApplicationType : null;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SMART_AGENT.DELETE_PRESSED({ service: ServiceType, taxobject: prepareTaxobject, taxtype: preparedPricingType }),
    );
  };

  const renderConstructionFee = ({ AdditionalData, Value, PricingType, ServiceType }: IFeeConstruction) => {
    const isServiceTypeAir = ServiceType === SERVICETYPE.AIR;
    const preparedName = ({
      [SERVICETYPE.AIR]: LABELS.FEES_FOR_AIR_TICKETS,
      [SERVICETYPE.TRAIN]: LABELS.FEES_FOR_TRAIN,
      [SERVICETYPE.HOTEL]: LABELS.FEES_FOR_HOTEL,
    })[ServiceType];

    const prepareAdditionalData = AdditionalData ? AdditionalData.ApplicationType : null;

    return (
      <ConstructionForm
        title={ preparedName }
        typeFee={ PricingType }
        valueFee={ Value }
        applicationFee={ prepareAdditionalData }
        serviceType={ ServiceType }
        addApplication={ isServiceTypeAir }
        onSave={ handleSave }
        onDelete={ handleDelete }
      />
    );
  };

  const renderTooltipContent = () => (
    <div className={ styles.tooltip_text }>
      <div className={ styles.tooltip_row }>
        <Text type='bold_14' color='white'>
          { LABELS.TOOLTIP.AGENCY_MODE }
        </Text>
        &nbsp;
        <Text type='NORMAL_14' color='white' className={ styles.tooltip_wrap }>
          { LABELS.TOOLTIP.FIRST }
        </Text>
      </div>
      <div className={ styles.tooltip_row }>
        <Text type='bold_14' color='white'>
          { LABELS.TOOLTIP.CLIENT_MODE }
        </Text>
        &nbsp;
        <Text type='NORMAL_14' color='white' className={ styles.tooltip_wrap }>
          { LABELS.TOOLTIP.SECOND }
        </Text>
      </div>
    </div>
  );

  const renderTitle = (
    <>
      <div>
        <Text type='bold_24'>
          { LABELS.TITLE }
        </Text>
      </div>
      <div className={ styles.sub_header_wrapper }>
        <Text type='NORMAL_14' color='gray' className={ styles.sub_header }>
          { LABELS.SUB_TITLE_FIRST }
        </Text>
        <div className={ styles.sub_header_tooltip }>
          <Text type='NORMAL_14' color='gray' className={ styles.sub_header }>
            { LABELS.SUB_TITLE_SECOND }
            &nbsp;
          </Text>
          <Text type='bold_14' color='gray'>
            { LABELS.SUB_TITLE_SUB_SECOND }
          </Text>
          <Tooltip
            className={ styles.tooltip }
            renderContent={ () => renderTooltipContent() }
          >
            <Icon type='info' className={ styles.icon } />
          </Tooltip>
        </div>
        <Text type='NORMAL_14' color='gray' className={ styles.sub_header }>
          { LABELS.SUB_TITLE_THIRD }
        </Text>
      </div>
    </>
  );

  const content = feeConstruction.map(renderConstructionFee);

  return (
    <div>
      { renderTitle }
      { content }
    </div>
  );
});

export { FeeConstruction };
